import React, { useEffect } from 'react';
import { BarDatum, BarTooltipProps, ResponsiveBar } from '@nivo/bar'
import { GetNumberFirstRegisteredGroupedByYear } from 'Communication/ModelCommunications';
import { INumberFirstRegistration } from 'Communication/models/Statistics';
import { Box, CircularProgress, Fade, MenuItem, Select, Theme, Typography } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Make, Model } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';

interface IProps {
  make?: Make;
  model?: Model,
  makeName: string,
  modelName?: string,
  firstRegisteredFilter: { fromYear: number, toYear: number }
  onFirstRegisteredChange: (filter: { fromYear: number, toYear: number }) => void;
  hiddenModelNames: string[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingPlaceholderContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
      minHeight: `calc(430px - ${theme.spacing(4)})`
    },
    fromYearSelect: {
      position: 'absolute',
      left: theme.spacing(1),
      bottom: theme.spacing(1),
      zIndex: 1,
    },
    toYearSelect: {
      position: 'absolute',
      right: theme.spacing(1),
      bottom: theme.spacing(1),
      zIndex: 1,
    }
  })
);

export default function NumberFirstRegisteredGraph(props: IProps) {
    const [numberFirstRegistered, setNumberFirstRegistered] = React.useState<BarDatum[]>();
    const { make, model, makeName, modelName, hiddenModelNames, firstRegisteredFilter, onFirstRegisteredChange } = props;
    const classes = useStyles();

    useEffect(() => {
      initialize();
    }, [make, model, makeName, modelName, hiddenModelNames, firstRegisteredFilter]);

    async function initialize() {
        RefreshNumberRegisteredInNetherlands();
    }

    async function RefreshNumberRegisteredInNetherlands() {
        let result = await GetNumberFirstRegisteredGroupedByYear(
          [...(make?.rdwSearchTerms ?? []), make?.name ?? makeName],
          modelName != undefined ? [...(model?.rdwSearchTerms ?? []), model?.name ?? modelName] : [],
          model?.rdwBlacklistSearchTerms != undefined ? hiddenModelNames.concat(model.rdwBlacklistSearchTerms) : hiddenModelNames,
          firstRegisteredFilter);
        setNumberFirstRegistered(result as any);
    }

    if (numberFirstRegistered == null)
      return <div className={classes.loadingPlaceholderContainer}><Fade in timeout={5000}><CircularProgress size={120} /></Fade></div>

    function renderTooltip(props: BarTooltipProps<BarDatum>) {
      let labelName = '';
      switch (props.id) {
        case 'inNetherlands': labelName = 'In Nederland'; break;
        case 'inOtherCountry': labelName = 'In buitenland'; break;
      }

      return <span>{labelName}: <b>{props.value}</b></span>
    }

    function getFromYearSelect () {
      let options = [<MenuItem key={'0'} value={'0'}>Alle</MenuItem>];
      if (numberFirstRegistered !== undefined) {
        numberFirstRegistered.filter(numberFirstRegistered => firstRegisteredFilter.toYear === 0 || Number.parseInt(numberFirstRegistered.registrationYear as string) <= firstRegisteredFilter.toYear).forEach(numberFirstRegistered => {
          options.push(<MenuItem key={numberFirstRegistered.registrationYear} value={numberFirstRegistered.registrationYear}>{numberFirstRegistered.registrationYear}</MenuItem>);
        });
      }

      return(<Select
        variant="standard"
        className={classes.fromYearSelect}
        value={firstRegisteredFilter.fromYear.toString()}
        onChange={e => onFirstRegisteredChange({ fromYear: Number.parseInt(e.target.value as string), toYear: firstRegisteredFilter.toYear })} >
          {options}
      </Select>)
    }

    function getToYearSelect () {
      let options = [<MenuItem key={'0'} value={'0'}>Alle</MenuItem>];
      if (numberFirstRegistered !== undefined) {
        numberFirstRegistered.filter(numberFirstRegistered => firstRegisteredFilter.fromYear === 0 || Number.parseInt(numberFirstRegistered.registrationYear as string) >= firstRegisteredFilter.fromYear).forEach(numberFirstRegistered => {
          options.push(<MenuItem key={numberFirstRegistered.registrationYear} value={numberFirstRegistered.registrationYear}>{numberFirstRegistered.registrationYear}</MenuItem>);
        });
      }

      return(<Select
        variant="standard"
        className={classes.toYearSelect}
        value={firstRegisteredFilter.toYear.toString()}
        onChange={e => onFirstRegisteredChange({ fromYear: firstRegisteredFilter.fromYear, toYear: Number.parseInt(e.target.value as string) })} >
          {options}
      </Select>)
    }

    return (
      <Box height={430} textAlign='center' position='relative'>
        <Typography variant="h5">Eerste registratie (nu in NL)</Typography>
        <ResponsiveBar
          data={numberFirstRegistered}
          keys={['inNetherlands', 'inOtherCountry']}
          indexBy='registrationYear'
          padding={0.1}
          labelSkipHeight={10}
          margin={{ top: 16, right: 32, bottom: 104, left: 56 }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 65,
            legend: 'Jaar',
            legendPosition: 'middle',
            legendOffset: 48
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Aantal',
            legendPosition: 'middle',
            legendOffset: -40
          }}
          tooltip={renderTooltip}
        />
        {getFromYearSelect()}
        {getToYearSelect()}
      </Box>
    )
}