import React, { useEffect } from 'react';
import { CircularProgress, Divider, Fade, Link, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { FormatModelName, Capitalize, FormatRegistration } from 'Helper/FormatterHelper';
import { IEngnineNetMaxPowerSummary } from 'Communication/models/Engine';
import { IRdwVehicleDetails } from 'Communication/models/Vehicle';
import makeStyles from '@mui/styles/makeStyles';
import { GetMostPowerfulEnginesSummary } from 'Communication/EngineCommunications';
import { GetVehiclesDetailsByLicenses } from 'Communication/VehicleCommunications';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingPlaceholderContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
      minHeight: `calc(500px - ${theme.spacing(4)})`
    },
    root: {
      padding: theme.spacing(2),
      textAlign: 'center'
    },
    divider: {
      margin: `${theme.spacing(1)} -${theme.spacing(2)}`
    }
  }
));

export default function MostPowerful() {
    const classes = useStyles();
    const [mostPowerfulEngines, setMostPowerfulEngines] = React.useState<IEngnineNetMaxPowerSummary[]>();
    const [mostPowerfulVehicles, setMostPowerfulVehicles] = React.useState<IRdwVehicleDetails[]>();

    useEffect(() => {
      initialize();
    }, []);

    async function initialize() {
      await refreshMostPowerfulEnginesAndEngines();
    }

    async function refreshMostPowerfulEnginesAndEngines() {
      let engines = await GetMostPowerfulEnginesSummary();
      setMostPowerfulEngines(engines);

      let vehicles = await GetVehiclesDetailsByLicenses(engines.map(e => e.license));
      setMostPowerfulVehicles(vehicles);
    }

    

    if (mostPowerfulEngines == undefined){
      return <div className={classes.loadingPlaceholderContainer}><Fade in timeout={5000}><CircularProgress size={120} /></Fade></div>
    }

    function findMostPowerfulVehicle(): IRdwVehicleDetails | undefined {
      if (mostPowerfulEngines == undefined || mostPowerfulVehicles == undefined)
      {
        return;
      }
      
      let mostpowerful = mostPowerfulVehicles[0];

      for (let engine of mostPowerfulEngines) {
        var vehicle = mostPowerfulVehicles.find(v => v.kenteken == engine.license)

        if (vehicle?.voertuigsoort == 'Personenauto' && vehicle?.aantal_cilinders > 8)
        {
          mostpowerful = vehicle;
          break;
        }
      }
      
      return mostpowerful;
    }
    
    const mostPowerfulVehicle = findMostPowerfulVehicle();
    const mostPowerfulEngine = mostPowerfulEngines.find(e => e.license === mostPowerfulVehicle?.kenteken);
    
    return (
        <div className={classes.root}>
          <Typography variant="h5">Meeste vermogen</Typography>
          <Divider className={classes.divider} />
          <Link href={`/make/${mostPowerfulVehicle?.merk.toUpperCase()}`}>
            <Typography variant="h2">{Capitalize(mostPowerfulVehicle?.merk)}</Typography>
          </Link>
          <Link href={`/model/${mostPowerfulVehicle?.merk.toUpperCase()}/${mostPowerfulVehicle?.handelsbenaming.toUpperCase()}`}>
            <Typography variant="h3">{Capitalize(FormatModelName(mostPowerfulVehicle?.merk, mostPowerfulVehicle?.handelsbenaming))}</Typography>
          </Link>
          <Link href={`/vehicle/NLD/${mostPowerfulVehicle?.kenteken}`}>
            <Typography variant="h5">{FormatRegistration(mostPowerfulVehicle?.kenteken ?? '')}</Typography>
          </Link>
          <br />
          <Typography variant="h3">{mostPowerfulEngine?.netMaxPower}kw ({Math.round((mostPowerfulEngine?.netMaxPower ?? 0) * 1.34102209)}pk)</Typography>
        </div>
    );
}