import React from 'react';
import 'firebase/analytics'
import firebase from "firebase/compat/app";
import './App.css';
import { v4 } from 'uuid';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import ReactGA from 'react-ga';
import { GetTheme } from './Styling/Theme';
import Frame from './Scenes/Frame';
import FireStoreHelper from 'Helper/FireStoreHelper';
import FireAuthHelper from 'Helper/FireAuthHelper';
import AnalyticsHelper from 'Helper/AnalyticsHelper';
import 'moment/locale/nl';


// declare module '@mui/material' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

export default function App() {

  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  if (isDev) {
    console.log('dev');
  } else {
    console.log('prod');
    ReactGA.initialize('G-6LDDRNX4XV', {
      gaOptions: {
        siteSpeedSampleRate: 100
      }
    });
  }

  const firebaseConfig = {
    apiKey: "AIzaSyCDMd8diyS4ERpjeP7xJJhsQu75o7gedRc",
    authDomain: "voertuigvinder.firebaseapp.com",
    databaseURL: "https://voertuigvinder.firebaseio.com",
    projectId: "voertuigvinder",
    storageBucket: "voertuigvinder.appspot.com",
    messagingSenderId: "978426815032",
    appId: "1:978426815032:web:615e8c77bf1e5318783ccd",
    measurementId: "G-6LDDRNX4XV"
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);

    if (!isDev) {
      AnalyticsHelper.SetAnalytics(firebase.analytics());
    }
  }

  FireStoreHelper.SetFireStore(firebase.firestore());
  let deviceToken = localStorage.getItem('deviceToken');
  if (deviceToken === undefined || deviceToken === '') {
    let deviceToken = v4();
    localStorage.setItem('deviceToken', deviceToken);
  }

  FireAuthHelper.SetUserOnStartup(deviceToken ?? '');

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={GetTheme()}>
          <Frame />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}