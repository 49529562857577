import React, { ChangeEvent, forwardRef, KeyboardEventHandler, MutableRefObject, useImperativeHandle } from 'react';
import "firebase/compat/analytics";
import Autocomplete, { AutocompleteInputChangeReason, AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { IRdwVehicleRegistrationBase } from 'Communication/models/Vehicle'
import { GetVehicleBaseByLicense } from 'Communication/VehicleCommunications';
import { useNavigate } from "react-router-dom";
import { FormatRegistration } from 'Helper/FormatterHelper';
import moment from 'moment';
import AnalyticsHelper from 'Helper/AnalyticsHelper';

interface IProps {
    className?: string;
    setRef?: MutableRefObject<any>;
}

let lastInputChange = moment.now();

const RegistrationAutocomplete = forwardRef((props: IProps, ref) => {
    const [inputValue, setInputValue] = React.useState('');
    const [simpleInputValue, setSimpleInputValue] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [vehicleSuggestions, setVehicleSuggestions] = React.useState<IRdwVehicleRegistrationBase[]>([]);

    useImperativeHandle(
        ref,
        () => ({
            toCurrentRegistration() {
                toCurrentRegistration()
            }
        }),
    )

    function onInputChange(event: ChangeEvent<{}>, newInputValue: string, reason: AutocompleteInputChangeReason) {
        if (reason === "reset") {
            return;
        }

        let modifiedValue = FormatRegistration(newInputValue);
        let simplifiedValue = modifiedValue.replace(/[^A-Z0-9\s]/g,'');
        setInputValue(modifiedValue);
        setSimpleInputValue(simplifiedValue);
        lastInputChange = moment.now();

        // Give registration as parameter in setSuggestions because state is not updated in async function
        setLoading(true);
        setTimeout(() => setSuggestions(simplifiedValue), 800);
    }

    async function setSuggestions(registration: string) {
        let timeSinceLastInput = moment.now() - lastInputChange;
        if (timeSinceLastInput < 800)
            return;

        if (registration.length < 5 || registration.length > 6){
            setVehicleSuggestions([]);
            setLoading(false);
            return;
        }

        var suggestions = await GetVehicleBaseByLicense(registration, 10);
        setVehicleSuggestions(suggestions);
        setLoading(false);
    }

    const history = useNavigate();
    function onChange(event: ChangeEvent<{}>, value: IRdwVehicleRegistrationBase | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<IRdwVehicleRegistrationBase> | undefined) {
        setInputValue('');
        if (value !== null) {
            logVehicleSearch();
            history('/vehicle/NLD/' + value?.kenteken);
        }
    }

    function onInputKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.key.toLocaleLowerCase().includes('enter') && simpleInputValue?.length == 6) {
            toCurrentRegistration();
        }
    }

    function toCurrentRegistration() {
        logVehicleSearch();
        history('/vehicle/NLD/' + simpleInputValue);
    }

    function logVehicleSearch() {
        AnalyticsHelper.LogEvent("vehicle_seach", { "registration": simpleInputValue });
    }

    return(<Autocomplete
        fullWidth
        noOptionsText={simpleInputValue.length < 5 ? 'Voer ten minste vijf tekens in' : 'Geen resultaten gevonden'}
        options={vehicleSuggestions}
        getOptionLabel={(option) => FormatRegistration(option.kenteken)}
        renderOption={(props, option) => (
            <li {...props}>
                <b>{FormatRegistration(option.kenteken)}</b>
                <span>&nbsp;-&nbsp;{option.merk} {option.handelsbenaming}</span>
            </li>
          )}
        inputValue={inputValue}
        onInputChange={onInputChange}
        onChange={onChange}
        value={null}
        popupIcon={false}
        className={props.className}
        loading={loading}
        renderInput={(params) => { params.inputProps.inputMode = 'search'; return (<TextField variant="standard" placeholder='Kenteken' {...params} onKeyDown={onInputKeyDown} />);}}
    />);
})

export default RegistrationAutocomplete;