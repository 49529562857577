import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Link, Modal, Paper, Stack, TextField, Theme, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import "firebase/compat/firestore";
import "firebase/compat/auth";
import React, { ChangeEvent, useEffect } from 'react';
import { CarRental, Instagram, YouTube } from '@mui/icons-material';
import TikTokIcon from 'Components/Icons/TikTokIcon';
import { v4 } from 'uuid';
import FireStoreAuthHelper from 'Helper/FireAuthHelper';
import { MakeSummary, ModelSummary, TrimSummary, User, Vehicle } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';
import { VoertuigVinderApi } from 'Communication/VoertuigVinderAPI/Client';
import { IRdwVehicleRegistrationBase } from 'Communication/models/Vehicle';
import { sortMakeSummariesByName } from 'Helper/MakeHelper';
import { sortModelSummariesByName } from 'Helper/ModelHelper';
import { sortTrimSummariesByName } from 'Helper/TrimHelper';

interface IProps {
    registration: string;
    countryCode: string;
    vehicle?: Vehicle;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toggleButton: {
            margin: `-0.5em 0 0 ${theme.spacing(1)}`,
            backgroundColor: theme.palette.primary.main,
            color: 'white',

            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            }
        },
    })
);

export default function MakeModelTrimModal(props: IProps) {
    const { countryCode, registration, vehicle } = props;
    const [user, setUser] = React.useState<User>();
    const [makeSummaries, setMakeSummaries] = React.useState<MakeSummary[]>([]);
    const [modelSummaries, setModelSummaries] = React.useState<ModelSummary[]>([]);
    const [trimSummaries, setTrimSummaries] = React.useState<TrimSummary[]>([]);
    const [selectedMake, setSelectedMake] = React.useState<MakeSummary | null>(null);
    const [selectedModel, setSelectedModel] = React.useState<ModelSummary | null>(null);
    const [selectedTrim, setSelectedTrim] = React.useState<TrimSummary | null>(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const classes = useStyles();

    FireStoreAuthHelper.OnUserSet(event => setUser(event.detail as User));

    async function openDialog() {
        setDialogOpen(true);

        if (vehicle != undefined) {
            setSelectedMake(vehicle.makeSummary ?? null);
            setSelectedModel(vehicle.modelSummary ?? null);
            setSelectedTrim(vehicle.trimSummary ?? null);
        }

        let makeSummariesResult = await VoertuigVinderApi.getMakeSummaries(true);
        setMakeSummaries(sortMakeSummariesByName(makeSummariesResult));
        await refreshModelSummaries(vehicle?.makeSummary?.id);
        await refreshTrimSummaries(vehicle?.modelSummary?.id);
    }

    async function refreshModelSummaries(makeId?: string) {
        if (makeId == undefined) {
            setModelSummaries([]);
            setSelectedModel(null);
        } else {
            let modelSummariesResult = await VoertuigVinderApi.getModelSummariesByMakeId(makeId);
            setModelSummaries(sortModelSummariesByName(modelSummariesResult));
        }
    }

    async function refreshTrimSummaries(modelId?: string) {
        if (modelId == undefined) {
            setTrimSummaries([]);
            setSelectedTrim(null);
        } else {
            let trimSummariesResult = await VoertuigVinderApi.getTrimSummariesByModelId(modelId);
            setTrimSummaries(sortTrimSummariesByName(trimSummariesResult));
        }
    }

    async function onSelectedMakeChange(event: ChangeEvent<{}>, value: MakeSummary | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<MakeSummary> | undefined) {
        setSelectedMake(value);
        await refreshModelSummaries(value?.id);
    }

    async function onSelectedModelChange(event: ChangeEvent<{}>, value: ModelSummary | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<ModelSummary> | undefined) {
        setSelectedModel(value);
        await refreshTrimSummaries(value?.id);
    }

    async function onSelectedTrimChange(event: ChangeEvent<{}>, value: TrimSummary | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<TrimSummary> | undefined) {
        setSelectedTrim(value);
    }

    async function saveChanges() {
        if (vehicle != undefined) {
            await VoertuigVinderApi.setMakeModelTrimById(vehicle.id!, { makeId: selectedMake?.id, modelId: selectedModel?.id, trimId: selectedTrim?.id });
        } else if (registration.length > 0 && countryCode.length > 0) {
            await VoertuigVinderApi.setMakeModelTrimByRegistrationAndCountryCode(registration, countryCode, { makeId: selectedMake?.id, modelId: selectedModel?.id, trimId: selectedTrim?.id });
        }

        window.location.reload();
    }

    return (
        <>
            <IconButton size='small' onClick={openDialog} className={classes.toggleButton}>
                <CarRental />
            </IconButton>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle id="modal-modal-title">Zet Merk/Model</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        fullWidth
                        options={makeSummaries}
                        getOptionLabel={(option) => option.name ?? ''}
                        onChange={onSelectedMakeChange}
                        value={selectedMake}
                        popupIcon={false}
                        renderInput={(params) => { params.inputProps.inputMode = 'search'; return (<TextField variant="standard" placeholder='Make' {...params} />); }}
                    />
                    <br/>
                    <Autocomplete
                        fullWidth
                        options={modelSummaries}
                        getOptionLabel={(option) => option.name ?? ''}
                        onChange={onSelectedModelChange}
                        value={selectedModel}
                        popupIcon={false}
                        renderInput={(params) => { params.inputProps.inputMode = 'search'; return (<TextField variant="standard" placeholder='Model' {...params} />); }}
                    />
                    <br/>
                    <Autocomplete
                        fullWidth
                        options={trimSummaries}
                        getOptionLabel={(option) => option.name ?? ''}
                        onChange={onSelectedTrimChange}
                        value={selectedTrim}
                        popupIcon={false}
                        renderInput={(params) => { params.inputProps.inputMode = 'search'; return (<TextField variant="standard" placeholder='Trim' {...params} />); }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setDialogOpen(false)}>Annuleren</Button>
                    <Button variant="contained" onClick={saveChanges}>Opslaan</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}