import { RdwRegistrations } from "Communication/VoertuigVinderAPI/VoertuigVinderApiClient";

function compareSummariesByName(a: RdwRegistrations, b: RdwRegistrations) {
    if (a.year == undefined || b.year == undefined)
        return 0;

    if ( a.year < b.year ){
      return -1;
    }
    if ( a.year > b.year ){
      return 1;
    }
    return 0;
}

export function sortRdwRegistrationsByYear(makeSummaries: RdwRegistrations[]) {
    return (Object.assign([], makeSummaries) as RdwRegistrations[]).sort(compareSummariesByName);
  }