export interface ICountry {
    name: string;
    dutchName: string;
    iso: string; // ISO 3166-1
    ilpcc?: string;
}

const countries: Readonly<ICountry[]> = [
    { name: 'Austria', dutchName: 'Oostenrijk', iso: 'AUT', ilpcc: 'A' },
    { name: 'Belgium', dutchName: 'België', iso: 'BEL', ilpcc: 'B' },
    { name: 'China', dutchName: 'China', iso: 'CHN' },
    { name: 'Germany', dutchName: 'Duitsland', iso: 'DEU', ilpcc: 'D' },
    { name: 'Czech Republic', dutchName: 'Tsjechië', iso: 'CZE', ilpcc: 'CZ' },
    { name: 'Denmark', dutchName: 'Denemarken', iso: 'DNK', ilpcc: 'DK' },
    { name: 'Spain', dutchName: 'Spanje', iso: 'ESP', ilpcc: 'E' },
    { name: 'France', dutchName: 'Frankrijk', iso: 'FRA', ilpcc: 'F' },
    { name: 'Croatia', dutchName: 'Kroatië', iso: 'HRV', ilpcc: 'HR' },
    { name: 'Italy', dutchName: 'Italië', iso: 'ITA', ilpcc: 'I' },
    { name: 'Japan', dutchName: 'Japan', iso: 'JPN' },
    { name: 'Luxembourg', dutchName: 'Luxemburg', iso: 'LUX', ilpcc: 'L' },
    { name: 'Malta', dutchName: 'Malta', iso: 'MLT', ilpcc: 'M' },
    { name: 'Monaco', dutchName: 'Monaco', iso: 'MCO', ilpcc: 'MC' },
    { name: 'Norway', dutchName: 'Noorwegen', iso: 'NOR', ilpcc: 'N' },
    { name: 'Netherlands', dutchName: 'Nederland', iso: 'NLD', ilpcc: 'NL' },
    { name: 'Portugal', dutchName: 'Portugal', iso: 'PRT', ilpcc: 'P' },
    { name: 'Poland', dutchName: 'Polen', iso: 'POL', ilpcc: 'PL' },
    { name: 'Romania', dutchName: 'Roemenië', iso: 'ROU', ilpcc: 'RO' },
    { name: 'Russia', dutchName: 'Rusland', iso: 'RUS', ilpcc: 'RUS' },
    { name: 'Sweden', dutchName: 'Zweden', iso: 'SWE', ilpcc: 'S' },
    { name: 'Switzerland', dutchName: 'Zwitserland', iso: 'CHE', ilpcc: 'CH' },
    { name: 'Slovakia', dutchName: 'Slowakije', iso: 'SVK', ilpcc: 'SK' },
    { name: 'Slovenia', dutchName: 'Slovenië', iso: 'SVN', ilpcc: 'SLO' },
    { name: 'Suriname', dutchName: 'Suriname', iso: 'SUR', ilpcc: 'SME' },
    { name: 'Serbia', dutchName: 'Servië', iso: 'SRB', ilpcc: 'SRB' },
    { name: 'South Korea', dutchName: 'Zuid Korea', iso: 'KOR', ilpcc: 'ROK' },
    { name: 'Thailand', dutchName: 'Thailand', iso: 'THA', ilpcc: 'T' },
    { name: 'Turkey', dutchName: 'Turkije', iso: 'TUR', ilpcc: 'TR' },
    { name: 'Ukraine', dutchName: 'Oekraïne', iso: 'UKR', ilpcc: 'UA' },
    { name: 'United Arab Emirates', dutchName: 'Verenigde Arabische Emiraten', iso: 'ARE', ilpcc: 'UAE' },
    { name: 'United Kingdom', dutchName: 'Verenigd Koninkrijk', iso: 'GBR', ilpcc: 'UK' },
    { name: 'United States', dutchName: 'Verenigde Staten', iso: 'USA', ilpcc: 'USA' },
];

export default countries;

function compareByDutchName(a: ICountry, b: ICountry) {
    if ( a.dutchName < b.dutchName ){
      return -1;
    }
    if ( a.dutchName > b.dutchName ){
      return 1;
    }
    return 0;
}

export function getCountriesSortedByDutchName() {
    return (Object.assign([], countries) as ICountry[]).sort(compareByDutchName);
}

function compareByName(a: ICountry, b: ICountry) {
  if ( a.name < b.name ){
    return -1;
  }
  if ( a.name > b.name ){
    return 1;
  }
  return 0;
}

export function getCountriesSortedByName() {
  return (Object.assign([], countries) as ICountry[]).sort(compareByDutchName);
}