import React, { useEffect } from 'react';
import "firebase/compat/storage";
import "firebase/compat/auth";
import 'react-virtualized/styles.css';
import { Theme, Box, Typography, Link, IconButton, Card, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import firebase from "firebase/compat/app";
import { GetVehiclesDetailsByLicenses } from 'Communication/VehicleCommunications';
import { IRdwVehicleDetails } from 'Communication/models/Vehicle';
import { RemoveCircle } from '@mui/icons-material';
import { FormatModelName, FormatRegistration } from 'Helper/FormatterHelper';
import { User, UserProfileVehicleSpot } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';
import { VoertuigVinderApi } from 'Communication/VoertuigVinderAPI/Client';
import AnalyticsHelper from 'Helper/AnalyticsHelper';
import { useParams } from "react-router-dom";
import { getThumb400Id } from 'Helper/PhotoHelper';
import { GetVehicleName, sortUserProfileVehicleSpotsByTakenOn } from 'Helper/VehicleSpotHelper';
import { Grid, WindowScroller, AutoSizer } from 'react-virtualized';
import FireStoreAuthHelper from 'Helper/FireAuthHelper';
import moment from 'moment';
import PhotoModal from 'Components/PhotoModal/PhotoModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    vehicleCard: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
      },
      vehicleDetailsBar: {
        background: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
        color: 'white',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0
      },
      spotImg: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        '&:hover': {
          cursor: 'pointer',
        }
      }
  })
);

export default function UserProfile() {
  AnalyticsHelper.LogScreenVisit("All user spots");
  const classes = useStyles();
  const [storageRef, setStorageRef] = React.useState<firebase.storage.Reference>();
  const [photoPaths, setPhotoPaths] = React.useState<string[]>([]);
  const [user, setUser] = React.useState<User>();
  const [currentUser, setCurrentUser] = React.useState<User | undefined>(FireStoreAuthHelper?.GetUser());

  const [isDeleting, setIsDeleting] = React.useState(false);
  const [spots, setSpots] = React.useState<UserProfileVehicleSpot[]>([]);
  const [vehiclesDetails, setVehiclesDetails] = React.useState<IRdwVehicleDetails[]>([]);
  const [spotCount, setSpotCount] = React.useState<number>(0);
  const [vehicleSpotToRemoveId, setVehicleSpotToRemoveId] = React.useState<string>();
  const [activePhotoId, setActivePhotoId] = React.useState<string>();
  let { userId = "" } = useParams();

  FireStoreAuthHelper.OnUserSet(event => setCurrentUser(event.detail as User));

  useEffect(() => {
    initialize();
  }, [userId]);

  async function initialize() {
    let user = await VoertuigVinderApi.getUserById(userId);
    setUser(user);

    if (user === undefined)
      return;

    let spotsResult = await VoertuigVinderApi.getApprovedUserProfileVehicleSpotsByUserId(user.id!, 99999, 1);
    let spotCount = await VoertuigVinderApi.getApprovedUserProfileVehicleSpotCountByUserId(user.id!);
    var registrations = spotsResult.map(v => v.vehicleRegistration!);
    var vehicleDetails = await GetVehiclesDetailsByLicenses(registrations);
    setVehiclesDetails(vehicleDetails);

    var activeStorageRef = storageRef ?? firebase.storage().ref().child('Vehicles').child('Spots-Thumb-400');
      
    if (storageRef === undefined){
      setStorageRef(activeStorageRef);
    }

    let photoPromises = spotsResult?.map(spot => activeStorageRef.child(spot != undefined ? getThumb400Id(spot.photoId!) : '').getDownloadURL()) ?? [];
    const photoPathsResult: string[] = await Promise.all(photoPromises);
    setPhotoPaths(photoPathsResult);

    setSpotCount(spotCount);
    setSpots(sortUserProfileVehicleSpotsByTakenOn(spotsResult).reverse());
  }

  async function removeVehicleSpot() {
    setIsDeleting(true)
    var spot = spots.find(s => s.vehicleSpotId == vehicleSpotToRemoveId);
    if (spot != undefined) {
      await VoertuigVinderApi.deleteSpotById(spot.vehicleId!, spot.vehicleSpotId!);
    }

    let spotsResult = await VoertuigVinderApi.getApprovedUserProfileVehicleSpotsByUserId(user!.id!, 99999, 1);
    let spotCount = await VoertuigVinderApi.getApprovedUserProfileVehicleSpotCountByUserId(user!.id!);
    setSpotCount(spotCount);
    setSpots(sortUserProfileVehicleSpotsByTakenOn(spotsResult).reverse());

    setVehicleSpotToRemoveId(undefined);
    setIsDeleting(false)
  }

  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const isXl = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));
  
  function getColumnCount() {
    if (isXl)
      return 6;
    else if (isLg)
      return 4;
    else if (isMd)
      return 3;
    else if (isSm)
      return 2;
    else
      return 1;
  }

  function renderSpot({columnIndex, key, rowIndex, style}) {
    var spot = spots[rowIndex * getColumnCount() + columnIndex];
    if (spot == undefined) {
      return(<Card key={key} className={classes.vehicleCard}>

      </Card>)
    }

    let vehicleDetails = vehiclesDetails.filter(vd => vd.kenteken === spot.vehicleRegistration)[0];
    let photoPath = photoPaths?.find(pp => pp.includes(getThumb400Id(spot.photoId!)))

    return (
      <div style={style}>
        <Box px={1} height={'100%'}>
          <Card key={key} className={classes.vehicleCard} >
            <div><img className={classes.spotImg} onClick={() => setActivePhotoId(spot.photoId)} src={photoPath} /></div>
            <div className={classes.vehicleDetailsBar}>
              <div>
                <Typography fontSize='12px'>{GetVehicleName(spot, vehicleDetails)}</Typography>
                <Typography fontSize='10px'>
                  {<Link color='#FFFF' href={`/vehicle/${spot.vehicleCountryCode}/${spot.vehicleRegistration}`}>{FormatRegistration(spot.vehicleRegistration)}</Link>} | {moment(spot.takenOn!).format('D MMMM YYYY')} | {spot.locationCity}
                  </Typography>
              </div>
                {(currentUser?.isAdmin || (user?.id != undefined && user?.id == currentUser?.id)) &&
                <IconButton
                  size='small'
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label='Verwijder spot'
                  onClick={() => setVehicleSpotToRemoveId(spot.vehicleSpotId)}
                >
                  <RemoveCircle />
                </IconButton>}
            </div>
          </Card>
        </Box>
      </div>
    );
  }

  return (
    <Box pb={6}>
      <PhotoModal photoId={activePhotoId} onClose={() => setActivePhotoId(undefined)} />
      <Typography variant="h1">Spots van {user?.userName}</Typography>
        <WindowScroller>
          {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
            <div>
              <AutoSizer disableHeight>
                {({width}) => {
                  let colWidth = width / getColumnCount();
                  let rowHeight = colWidth / (16/9);

                  return(
                  <div ref={registerChild as any}>
                    <Grid
                      autoHeight
                      cellRenderer={renderSpot}
                      columnCount={getColumnCount()}
                      columnWidth={colWidth}
                      height={height}
                      rowCount={Math.ceil(spotCount / getColumnCount())}
                      rowHeight={rowHeight}
                      width={width}
                      onScroll={onChildScroll}
                      isScrolling={isScrolling}
                      scrollTop={scrollTop}
                    />
                  </div>
                )}}
              </AutoSizer>
            </div>
          )}
        </WindowScroller>
        <Dialog
          open={vehicleSpotToRemoveId !== undefined}
          onClose={() => setVehicleSpotToRemoveId(undefined)}
        >
          <DialogTitle>Spot verwijderen</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Weet je zeker dat je deze spot wilt verwijderen?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setVehicleSpotToRemoveId(undefined)}>Annuleren</Button>
            <Button disabled={isDeleting} onClick={removeVehicleSpot} autoFocus>
              Verwijderen
            </Button>
          </DialogActions>
        </Dialog>
    </Box>
  );
}