import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import AnalyticsHelper from "./AnalyticsHelper";
import { VoertuigVinderApi } from 'Communication/VoertuigVinderAPI/Client'
import { User } from "Communication/VoertuigVinderAPI/VoertuigVinderApiClient";

const userSetEventName = 'userSet'

export default class FireStoreAuthHelper {
    private static user? : User;
    private static deviceToken: string;

    public static async SignIn() {
        var provider = new firebase.auth.GoogleAuthProvider();
        await firebase.auth()
            .signInWithPopup(provider);
        
        window.location.reload();
    }

    public static async SignOut() {
        await firebase.auth().signOut();
        FireStoreAuthHelper.user = undefined;
        window.location.reload();
    }

    private static isChanging = false;

    private static async AddOrSetUserNew(newUser: firebase.User | null) {
        if (newUser != undefined && FireStoreAuthHelper.isChanging === false) {
            FireStoreAuthHelper.isChanging = true;
            
            AnalyticsHelper.SetUserId(newUser?.uid ?? this.deviceToken);
            FireStoreAuthHelper.user = await VoertuigVinderApi.getUser();
            FireStoreAuthHelper.isChanging = false;

            const event = new CustomEvent(userSetEventName, { detail: FireStoreAuthHelper.user });
            document.dispatchEvent(event);
        }
    }

    public static async SetUserOnStartup(deviceToken: string) {
        this.deviceToken = deviceToken;
        firebase.auth().onAuthStateChanged(user => FireStoreAuthHelper.AddOrSetUserNew(user));
    }

    public static GetUser = () => FireStoreAuthHelper.user;

    public static OnUserSet(action: (event: any) => void) {
        document.addEventListener(userSetEventName, action);
    }
}