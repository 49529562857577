import { IRdwVehicleRegistrationBase, IRdwVehicleDetails } from './models/Vehicle';
import ExecuteQuery from './RdwCommunication';

let vehicleBase: Readonly<string> = '$select=kenteken,merk,handelsbenaming,voertuigsoort&';

export async function GetVehicleBaseByLicense(license: string, limit: number = 100): Promise<IRdwVehicleRegistrationBase[]> {
    let query = vehicleBase + '$where=kenteken LIKE\'%25' + license + '%25\'&$limit=' + limit;
    let result = await ExecuteQuery(query);
    return result;
}

export async function GetVehicleDetailsByLicense(license: string): Promise<IRdwVehicleDetails> {
    let query = 'kenteken=' + license + '&$limit=1';
    let result = await ExecuteQuery(query);
    return (result as IRdwVehicleDetails[])[0];
}

export async function GetVehiclesDetailsByLicenses(licenses: string[]): Promise<IRdwVehicleDetails[]> {
    let query = '$where=kenteken in (\'' + licenses.join('\',\'') + '\')';

    let result = [];
    try
    {
        result = await ExecuteQuery(query);

    }catch (e) {

    }
    return (result as IRdwVehicleDetails[]);
}

export async function GetOldestVehicles(vehicleType: string, limit: number = 3): Promise<IRdwVehicleDetails[]> {
    let query = `$order=datum_eerste_toelating&$limit=${limit}&voertuigsoort=${vehicleType}`;
    let result = await ExecuteQuery(query);
    return (result as IRdwVehicleDetails[]);
}