import React, { useEffect } from 'react';
import { AppBar, Button, Dialog, IconButton, TextField, Theme, Toolbar, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import moment from 'moment';
import { Close } from '@mui/icons-material';
import ExecuteQuery, { ExecuteEngineQuery } from 'Communication/RdwCommunication';
import { groupBy, forEach, orderBy } from 'lodash';
import FireStoreHelper from 'Helper/FireStoreHelper';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  })
);

export default function FuelDataFillerTakeOver() {
  const theme = useTheme();
  const [fireUser, setFireUser] = React.useState<firebase.User>();
  const [isOpen, setIsOpen] = React.useState(false);
  const [year, setYear] = React.useState('');
  const classes = useStyles();

  useEffect(() => {
    // initialize();
  }, []);

  firebase.auth().onAuthStateChanged(function(newUser) {
    if (newUser) {
      setFireUser(newUser);
    } else {
      setFireUser(undefined);
    }
  });

  if(!Boolean(fireUser) || fireUser?.uid !== 'bYd1xzJkGqURPdyh2Q6zXq8pcT53') {
    return <></>;
  }

  async function getAllRegistrationsFromYear() {
    if (year.length != 4)
      return;

    let registrationLimit = 5000;
    let registrationPage = 0;
    let reachedLastRegistrationPage = false;

    let registrations: { kenteken: string }[] = [];
    while (!reachedLastRegistrationPage){
      let query = `$where=datum_eerste_tenaamstelling_in_nederland>=${year}0000 AND datum_eerste_tenaamstelling_in_nederland<=${year}3131&voertuigsoort=Personenauto&$select=kenteken&$limit=${registrationLimit}&$offset=${registrationLimit * registrationPage}`;
      let result = (await ExecuteQuery(query)) as { kenteken: string }[];
      reachedLastRegistrationPage = result.length < registrationLimit;
      registrations = registrations.concat(result);
      console.log(`Registrations page: ${registrationPage} reached`);
      registrationPage++;
    }

    console.log('Registrations:');
    console.log(registrations);

    let fuelLimit = 500;
    let reachedLastFuelPage = false;
    let fuelData: { fuel: string, count: number }[] = [];
    while (!reachedLastFuelPage) {
      let usedRegistrations = registrations.splice(0, fuelLimit);
      let query = `$select=brandstof_omschrijving,kenteken&$where=kenteken in ('${usedRegistrations.map(r => r.kenteken).join('\', \'')}')`;
      let result = (await ExecuteEngineQuery(query)) as { brandstof_omschrijving: string, kenteken: string }[];
      let groupedResult = groupBy(result, e => e.kenteken);

      forEach(groupedResult, group => {
        let fuelName = orderBy(group.map(f => f.brandstof_omschrijving), f => f, 'asc').join('+');
        let existingFuelData = fuelData.find(fd => fd.fuel === fuelName);
        if (existingFuelData === undefined) {
          fuelData.push({ fuel: fuelName, count: 1 });
        }else {
          existingFuelData.count++;
        }
      });
      
      console.log(`${registrations.length} registrations left`);
      reachedLastFuelPage = registrations.length === 0;
    }

    console.log('Fuel data:');
    console.log(fuelData);

    let fleetStatisticsDocument = FireStoreHelper.fireStore?.collection('fleet').doc('statistics')!;
    let existingFireData = await fleetStatisticsDocument.get().then((doc) => {
      return doc.data()!;
    });

    if (existingFireData.fuelPerYear[year] === undefined) {
      existingFireData.fuelPerYear[year] = {};
    }

    fuelData.forEach(fd => {
      existingFireData.fuelPerYear[year][fd.fuel] = fd.count;
    });

    await fleetStatisticsDocument.update(existingFireData).then(function() {
        console.log("Document successfully updated!");
    })
    .catch(function(error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    });

    console.log(`Updated Fuel for ${year}`);
    setYear('');
  }

  return <>
    <Button onClick={() => setIsOpen(!isOpen)}>Open Data Filler</Button>
    <Dialog fullScreen open={isOpen} onClose={() => setIsOpen(false)}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setIsOpen(false)}
            size="large">
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div style={{paddingTop: 160}}>
        <TextField variant="standard" value={year} onChange={e => setYear(e.target.value)} />
        <Button onClick={() => getAllRegistrationsFromYear()}>Get Data</Button>
      </div>
    </Dialog>
  </>;
}