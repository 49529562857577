import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
  } from 'react-router-dom';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import CookieDialog from '../Components/CookieDialog/CookieDialog';
import Home from './Home/Home';
import Vehicle from './Vehicle/Vehicle';
import MakeModel from './MakeModel/MakeModel';
import Tops from './Tops/Tops';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { grey } from '@mui/material/colors';
import NationalFleet from './NationalFleet/NationalFleet';
// import { useCookies } from 'react-cookie';
import Admin from './Admin/Admin';
import ApprovePhotos from './Admin/Scenes/ApprovePhotos/ApprovePhotos';
import UserProfile from './UserProfile/UserProfile';
import ApproveSocialMedia from './Admin/Scenes/ApproveSocialMedia/ApproveSocialMedia';
import MakeOverview from './Overview/MakeOverview';
import ManageMakes from './Admin/Scenes/MakeModelTrim/ManageMakes';
import ManageMake from './Admin/Scenes/MakeModelTrim/ManageMake/ManageMake';
import ManageModel from './Admin/Scenes/MakeModelTrim/ManageModel/ManageModel';
import ManageTrim from './Admin/Scenes/MakeModelTrim/ManageTrim/ManageTrim';
import AllSpots from './UserProfile/Components/AllSpots/AllSpots';
import RegistrationSearch from './RegistrationSearch/RegistrationSearch';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            backgroundColor: grey[100],
        },
        inner: {
            padding: `${theme.spacing(8)} ${theme.spacing(4)} 0 ${theme.spacing(4)}`,
            [theme.breakpoints.down('md')]: {
                padding: `${theme.spacing(8)} ${theme.spacing(1)} 0 ${theme.spacing(1)}`,
            },
            minHeight: `calc(100vh - ${theme.spacing(13)} - 1px)`,
        }
    })
);

export default function Frame() {
    const classes = useStyles();
    // const [cookies, setCookie] = useCookies(['cookie-popup']);

    function setCookieAccepted(accepted: boolean) {
        // setCookie('cookie-popup', accepted);
        window.location.reload();
    }

    return (
        <Router>
            <div className={classes.content}>
                <Header />
                {/* <CookieDialog open={cookies['isAccepted'] != 'true'} onCookieAccepted={setCookieAccepted} /> */}
                <div className={classes.inner}>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/kenteken-vinden" element={<RegistrationSearch />} />
                        <Route path="/national-fleet" element={<NationalFleet />} />
                        <Route path="/vehicle/:country/:license" element={<Vehicle />} />
                        <Route path="/make/:makeName" element={<MakeModel />} />
                        <Route path="/make-overview" element={<MakeOverview />} />
                        <Route path="/model/:makeName/:modelName" element={<MakeModel />} />
                        <Route path="/admin" element={<Admin />} />
                        <Route path="/admin/approve-photos" element={<ApprovePhotos />} />
                        <Route path="/admin/approve-social-media" element={<ApproveSocialMedia />} />
                        <Route path="/admin/manage-makes" element={<ManageMakes />} />
                        <Route path="/admin/manage-makes/add" element={<ManageMake isEdit={true} />} />
                        <Route path="/admin/manage-makes/:makeId" element={<ManageMake isEdit={false} />} />
                        <Route path="/admin/manage-makes/:makeId/edit" element={<ManageMake isEdit={true} />} />
                        <Route path="/admin/manage-makes/:makeId/models/add" element={<ManageModel isEdit={true} />} />
                        <Route path="/admin/manage-makes/models/:modelId/edit" element={<ManageModel isEdit={true} />} />
                        <Route path="/admin/manage-makes/models/:modelId/trims/add" element={<ManageTrim isEdit={true} />} />
                        <Route path="/admin/manage-makes/trims/:trimId/edit" element={<ManageTrim isEdit={true} />} />
                        <Route path="/user/profile/:userId" element={<UserProfile />} />
                        <Route path="/user/profile/:userId/all-spots" element={<AllSpots />} />
                        <Route path="/qr/up/:userId" element={<UserProfile />} />
                        <Route path="/tops" element={<Tops />} />
                    </Routes>
                </div>
                <Footer />
            </div>
        </Router>
    );
  }