import { ExecuteAxleQuery } from './RdwCommunication';
import { IAxle } from './models/Axle';

export async function GetAxleDetailsByLicense(license: string): Promise<IAxle[]> {
    let selectQuery = `$select=kenteken as license, as_nummer as axleNumber, aantal_assen as totalAxleCount, plaatscode_as as location, spoorbreedte as trackWidth, wettelijk_toegestane_maximum_aslast as maxAllowedLoadLaw, technisch_toegestane_maximum_aslast as maxAllowedLoadTechnical, aangedreven_as as driven`;
    let whereQuery = `&$where=kenteken='${license}'`
    let result: IAxle[] = await ExecuteAxleQuery(selectQuery + whereQuery);
    result.forEach(element => {
        element.axleNumber = +element.axleNumber;
        element.totalAxleCount = +element.totalAxleCount;
        element.trackWidth = +element.trackWidth;
        element.maxAllowedLoadLaw = +element.maxAllowedLoadLaw;
        element.maxAllowedLoadTechnical = +element.maxAllowedLoadTechnical;
    });

    return result;
}