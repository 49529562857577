import { ExecuteEngineQuery } from './RdwCommunication';
import { IEngine, IEngnineNetMaxPowerSummary } from './models/Engine';

export async function GetEngineDetailsByLicense(license: string): Promise<IEngine[]> {
    let selectQuery = `$select=kenteken as license, brandstof_volgnummer as fuelTrackingNumber, brandstof_omschrijving as fuelDescription, co2_uitstoot_gecombineerd as co2EmissionCombined, geluidsniveau_stationair as soundLevelStationary, emissiecode_omschrijving as emissionCodeDescription, nettomaximumvermogen as netMaxPower, toerental_geluidsniveau as rpmSoundLevel`;
    let whereQuery = `&$where=kenteken='${license}'`
    let result: IEngine[] = await ExecuteEngineQuery(selectQuery + whereQuery);
    result.forEach(element => {
        element.fuelTrackingNumber = element.fuelTrackingNumber && +element.fuelTrackingNumber;
        element.co2EmissionCombined = element.co2EmissionCombined && +element.co2EmissionCombined;
        element.soundLevelStationary = element.soundLevelStationary && +element.soundLevelStationary;
        element.emissionCodeDescription = element.emissionCodeDescription && +element.emissionCodeDescription;
        element.netMaxPower = element.netMaxPower && +element.netMaxPower;
        element.rpmSoundLevel = element.rpmSoundLevel && +element.rpmSoundLevel;
    });

    return result
}

export async function GetMostPowerfulEnginesSummary(): Promise<IEngnineNetMaxPowerSummary[]> {
    let selectQuery = `$select=kenteken as license,nettomaximumvermogen as netMaxPower`;
    let whereQuery = `&$where=nettomaximumvermogen LIKE '_______' and toerental_geluidsniveau is not null`;
    let orderQuery = `&$order=nettomaximumvermogen DESC`;

    let result: IEngine[] = await ExecuteEngineQuery(selectQuery + whereQuery + orderQuery);
    result.forEach(element => {
        element.netMaxPower = element.netMaxPower && +element.netMaxPower;
    });

    return result;
}