import { UserProfileOwnedVehicle } from "Communication/VoertuigVinderAPI/VoertuigVinderApiClient";
import { IRdwVehicleDetails } from "Communication/models/Vehicle";
import { FormatMakeName, FormatModelName } from "./FormatterHelper";

export function GetOwnedVehicleName(ownedVehicle?: UserProfileOwnedVehicle, rdwVehicleDetails?: IRdwVehicleDetails) {
    let makeName = ownedVehicle?.makeSummary?.name ?? FormatMakeName(rdwVehicleDetails?.merk) ?? "Onbekend";
    let modelName = ownedVehicle?.trimSummary ?? ownedVehicle?.modelSummary?.name ?? FormatModelName(rdwVehicleDetails?.merk, rdwVehicleDetails?.handelsbenaming) ?? "Onbekend";
    
    if (makeName == modelName)
        return makeName;

    return `${makeName} ${modelName}`;
}