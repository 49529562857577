import React, { useEffect } from 'react';
import { AppBar, Box, Button, CircularProgress, Dialog, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme, Toolbar, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import firebase from "firebase/compat/app";
import { VoertuigVinderApi } from 'Communication/VoertuigVinderAPI/Client';
import "firebase/compat/firestore";
import "firebase/compat/auth";
import moment from 'moment';
import { Close } from '@mui/icons-material';
import { RdwRegistrations, VehicleType } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';
import { sortRdwRegistrationsByYear } from 'Helper/RdwRegistrationsHelper';

interface IProps {
    makeId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  })
);

export default function FuelDataFillerTakeOver(props: IProps) {
  const { makeId } = props;
  const theme = useTheme();
  const [fireUser, setFireUser] = React.useState<firebase.User>();
  const [isOpen, setIsOpen] = React.useState(false);
  const [rdwRegistrations, setRdwRegistrations] = React.useState<RdwRegistrations[]>();
  const classes = useStyles();

  useEffect(() => {
    initialize();
  }, []);

  async function initialize() {
    var existingRdwRegistrations = await VoertuigVinderApi.getRdwRegistrationsByMakeId(makeId);
    setRdwRegistrations(sortRdwRegistrationsByYear(existingRdwRegistrations));
  }

  firebase.auth().onAuthStateChanged(function(newUser) {
    if (newUser) {
      setFireUser(newUser);
    } else {
      setFireUser(undefined);
    }
  });

  async function updateRdwRegistrations() {
    setRdwRegistrations(undefined);
    await VoertuigVinderApi.updateRdwRegistrationsForMake(makeId, VehicleType.PersonalCar);
    var existingRdwRegistrations = await VoertuigVinderApi.getRdwRegistrationsByMakeId(makeId);
    setRdwRegistrations(sortRdwRegistrationsByYear(existingRdwRegistrations));
  }

  function renderExistingRegistrations() {
    if (rdwRegistrations === undefined)
        return <CircularProgress />;

    return(
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Jaar</TableCell>
                <TableCell align="right">Nederlands</TableCell>
                <TableCell align="right">Import</TableCell>
                <TableCell align="right">Updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rdwRegistrations.map((row) => (
                <TableRow
                  key={row.year}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.year}
                  </TableCell>
                  <TableCell align="right">{row.numberOfNewVehiclesInNetherlands}</TableCell>
                  <TableCell align="right">{row.numberOfImportVehicleInNetherlands}</TableCell>
                  <TableCell align="right">{row.lastUpdatedOn != undefined ? moment(row.lastUpdatedOn).calendar() : ''}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    );
  }

  return <>
    <Button onClick={() => setIsOpen(!isOpen)}>Open Data Filler</Button>
    <Dialog fullScreen open={isOpen} onClose={() => setIsOpen(false)}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setIsOpen(false)}
            size="large">
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box p={2} pt={10}>
        <Box mb={2}>{renderExistingRegistrations()}</Box>
        <Button variant='contained' fullWidth disabled={rdwRegistrations == undefined} onClick={() => updateRdwRegistrations()}>Update</Button>
      </Box>
    </Dialog>
  </>;
}