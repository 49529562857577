import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material';
import { IEngine } from 'Communication/models/Engine';
import { IRdwVehicleDetails } from 'Communication/models/Vehicle';
import { orderBy } from 'lodash';
import React from 'react';

interface IProps {
    engineDetails?: IEngine[];
    vehicleDetails?: IRdwVehicleDetails;
}

export default function EngineSpecifications(props: IProps) {
    const { engineDetails,vehicleDetails } = props;

    // useEffect(() => {
    // }, [engines]);

    let hasCombustionEngine = engineDetails?.some((ed: IEngine) => ed.fuelDescription != 'Elektriciteit');
    let usesFossilFuels = hasCombustionEngine && engineDetails?.some((ed: IEngine) => ed.fuelDescription != 'Waterstof');
    
    let maxPower = engineDetails && Math.max(...engineDetails.filter(ed => ed.netMaxPower !== undefined).map(ed => ed.netMaxPower));
    let mostPowerFullEngine = engineDetails?.find(ed => ed.netMaxPower === maxPower);

    let loudestVolume = engineDetails && Math.max(...engineDetails.filter(ed => ed.soundLevelStationary !== undefined).map(ed => ed.soundLevelStationary));
    let loudestEngine = engineDetails?.find(ed => ed.soundLevelStationary === loudestVolume);

    let highestPollution = engineDetails && Math.max(...engineDetails.filter(ed => ed.co2EmissionCombined !== undefined).map(ed => ed.co2EmissionCombined));
    let mostPollutingEngine = engineDetails?.find(ed => ed.co2EmissionCombined === highestPollution);

    return (
        <>
          <Typography variant="h6">Motorspecificaties</Typography>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableBody>
                {hasCombustionEngine &&
                  <>
                    <TableRow>
                      <TableCell component='th'>Cilinders</TableCell>
                      <TableCell>{vehicleDetails?.aantal_cilinders ?? 'Onbekend'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component='th'>Cilinderinhoud</TableCell>
                      <TableCell>{vehicleDetails?.cilinderinhoud != undefined ? `${vehicleDetails?.cilinderinhoud}cc` : 'Onbekend'}</TableCell>
                    </TableRow>
                  </>
                }
                <TableRow>
                  <TableCell component='th'>Vermogen</TableCell>
                  <TableCell>{mostPowerFullEngine?.netMaxPower ? `${mostPowerFullEngine?.netMaxPower}kw (${mostPowerFullEngine?.netMaxPower && (Math.round(mostPowerFullEngine?.netMaxPower * 1.34102209))}hp)` : 'Onbekend'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th'>Brandstof</TableCell>
                  <TableCell>{orderBy(engineDetails?.map(ed => ed.fuelDescription)).join(' + ')}</TableCell>
                </TableRow>
                {usesFossilFuels &&
                  <>
                    <TableRow>
                      <TableCell component='th'>Geluidsniveau</TableCell>
                      <TableCell>{loudestEngine?.soundLevelStationary ? `${loudestEngine?.soundLevelStationary}dB @ ${loudestEngine?.rpmSoundLevel} toeren` : 'Onbekend'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component='th'>CO2 uitstoot</TableCell>
                      <TableCell>{mostPollutingEngine?.co2EmissionCombined ? `${mostPollutingEngine?.co2EmissionCombined} g/km` : 'Onbekend'}</TableCell>
                    </TableRow>
                  </>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </>
    );
}