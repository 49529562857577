import React, { useEffect } from 'react';
import { Divider, Grid, Paper, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import MostPowerful from './Components/MostPowerful';
import Oldest from './Components/Oldest';
import AnalyticsHelper from 'Helper/AnalyticsHelper';
import Seo from 'Components/Seo/Seo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
        paddingBottom: theme.spacing(6)
      },
      title: {
        fontSize: '4em',
        fontWeight: 600,
      },
      divider: {
        margin: `${theme.spacing(2)} -${theme.spacing(4)}`,
        [theme.breakpoints.down('md')]: {
          margin: `${theme.spacing(2)} -${theme.spacing(1)}`,
        },
      }
  })
);

export default function Tops() {
  useEffect(() => {
    AnalyticsHelper.LogScreenVisit("Tops");
  }, []);

  const classes = useStyles();
  return (
      <div className={classes.container}>
        <Seo title='Tops' />
        <div>
          <span className={classes.title}>Tops</span>
        </div>
        <Divider className={classes.divider} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={3}>
            <Paper>
              <MostPowerful />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Paper>
              <Oldest />
            </Paper>
          </Grid>
        </Grid>
      </div>
  );
}