export function FormatMakeName(make: string | undefined): string {
    if (make === undefined) {
        return "";
    }

    let newMakeName = make.trim();
    newMakeName = newMakeName.replaceAll('%26', '&').replaceAll('%2F', '/');
    return newMakeName.trim().toUpperCase();
}

export function FormatModelName(make: string | undefined, model: string | undefined): string {
    if (make === undefined || model === undefined) {
        return "";
    }

    let newModelName = model.trim();

    if (newModelName.startsWith(make)) {
        newModelName = newModelName.replace(make, "");
    }

    newModelName = newModelName.replaceAll('%26', '&');
    newModelName = newModelName.replaceAll('%2F', '/');

    return newModelName.trim().toLowerCase();
}

export function Capitalize(text: string | undefined) {
    if (text === undefined || text.length < 2){
        return text;
    }

    let firstCharacter = text[0];
    let otherCharacters = text.substr(1);

    return firstCharacter.toUpperCase() + otherCharacters.toLowerCase();
}

export function FormatRegistration(input: string | undefined) {
    input ??= "";
    let lastCharIsDash = input.charAt(input.length - 1) == '-';

    // https://stackoverflow.com/questions/9742110/splitting-numbers-and-letters-in-string-which-contains-both
    var parts: string[] = [];
    input.match(/[a-zA-Z]+|[0-9]+/g)?.forEach(part => {
        if (part.length > 3) {
            parts.push(part.substring(0, 2));
            parts.push(part.substring(2));
        } else {
            parts.push(part);
        }
    })

    var mergedParts = parts?.join('-') ?? '';
    if (lastCharIsDash)
    {
        mergedParts = mergedParts + '-';
    }

    return mergedParts.toUpperCase();
}