import "firebase/compat/auth";
import React, { useEffect } from 'react';
import { AppBar, Box, Button, Card, CardContent, Checkbox, CircularProgress, Dialog, FormControlLabel, Grid, IconButton, ImageListItem, Paper, TextField, Theme, Toolbar, Typography, useMediaQuery } from "@mui/material";
import moment from 'moment';
import { v4 } from 'uuid';
import FireStoreAuthHelper from "Helper/FireAuthHelper";
import { AddAPhoto, Close } from "@mui/icons-material";
import { AddSpot, User, Vehicle } from "Communication/VoertuigVinderAPI/VoertuigVinderApiClient";
import { VoertuigVinderApi } from "Communication/VoertuigVinderAPI/Client";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import FilerobotImageEditor, { TABS } from 'react-filerobot-image-editor';
import FireAuthHelper from "Helper/FireAuthHelper";

interface IProps {
    countryCode: string;
    registration: string;
    vehicle?: Vehicle;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addTile: {
      cursor: 'pointer',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.grey[300],
      borderRadius: 8,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'relative'
    },
    photoInput: {
      cursor: 'pointer',
      opacity: 0,
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    cameraIcon: {
      color: theme.palette.grey[600],
      height: '30%',
      width: '30%',
    },
    addPhotoTitle: {
      textAlign: 'center',
      fontWeight: 500,
      color: theme.palette.grey[700],
      maxWidth: '100%'
    },
    metaDataContainer: {
      width: '100%',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    }
  })
);

export default function AddPhotoDialog(props: IProps) {
    const { countryCode, registration, vehicle } = props;
    const [isOpen, setIsOpen] = React.useState(false);
    const [user, setUser] = React.useState<User | undefined>(FireStoreAuthHelper?.GetUser());
    const [photo, setPhoto] = React.useState<FileList>();
    const [photoUrl, setPhotoUrl] = React.useState('');
    const [editedPhotoData, setEditedPhotoData] = React.useState<savedImageData | undefined>();
    const [takenOn, setTakenOn] = React.useState(moment().format('YYYY-MM-DD'));
    const [location, setLocation] = React.useState('');
    const [isHighwaySpot, setIsHighwaySpot] = React.useState(false);
    const [fileInput, setFileInput] = React.useState<HTMLInputElement>();
    const [isUploading, setIsUploading] = React.useState(false);

    const classes = useStyles();

    const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const isLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const isXl = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));

    useEffect(() => {
      setUser(FireStoreAuthHelper?.GetUser());
    }, [countryCode, registration, vehicle]);

    FireStoreAuthHelper.OnUserSet(event => { 
      setUser(event.detail as User);
    });

    function ClearInput() {
      setPhoto(undefined);
      setTakenOn(moment().format('YYYY-MM-DD'));
      setLocation('');
      setEditedPhotoData(undefined);
      setPhotoUrl('');

      if (fileInput !== undefined)
        fileInput.value = null as any;
    }

    async function AddNewPhoto() {
      let takenOnMoment = moment(takenOn, "YYYY-MM-DD");
      let user = FireStoreAuthHelper.GetUser();

      if (!photo || (countryCode == 'NLD' && registration.length !== 6) || takenOnMoment > moment() || takenOnMoment < moment(takenOn, "2020-01-01") || !user)
        return;

      const reader = new FileReader();
      reader.onloadend = async (e) => {
        let fileString = reader.result as string;
        
        let addSpot: AddSpot = {
          locationCity: !isHighwaySpot ? location : (location.length > 0 ? location : undefined),
          locationCountry: "NLD",
          takenOn: takenOnMoment.toDate(),
          vehicleSpotId: v4(),
          fileName: editedPhotoData?.fullName,
          isHighwaySpot: isHighwaySpot,
          base64Photo: editedPhotoData?.imageBase64
        };

        await VoertuigVinderApi.addSpotToVehicleByRegistrationAndCountryCode(registration, countryCode, addSpot);

        ClearInput();
        setIsUploading(false);
        setIsOpen(false);
      };
      
      setIsUploading(true);
      reader.readAsDataURL(photo[0]);
    }

    function AllValuesValid(): boolean {
      return photo !== undefined &&
      photo.length > 0 &&
      (photo[0].type === "image/jpeg" || photo[0].type === "image/png"  || photo[0].type === "image/webp") &&
      (isHighwaySpot ||
      (location !== undefined &&
      location.length > 2)) &&
      moment(takenOn, "YYYY-MM-DD") < moment();
    }

    function ChoosePhoto(event: React.ChangeEvent<HTMLInputElement>) {
      if (user == undefined)
        return;

      let files = event.target.files ?? undefined;
      setPhoto(files);
      setFileInput(event.target);

      if (files == undefined)
        return;

      const reader = new FileReader();
      reader.onloadend = async (e) => {
        let fileString = reader.result as string;
        
        setPhotoUrl(fileString);
        
        setIsOpen(true);
      };
      reader.readAsDataURL(files[0]);
    }

      function OnPhotoEditSave(savedImageData: savedImageData) {
        setEditedPhotoData(savedImageData);
      }
    
      function PhotoEditor() {
        if (photo == undefined || photo.length == 0 || photoUrl.length == 0)
          return <div></div>;

      function GetSavingPixelAspectRatio() {
        if (isXl)
          return 11

        if (isLg)
          return 13

        return 16
      }

      return (
        <FilerobotImageEditor
            source={photoUrl}
            savingPixelRatio={GetSavingPixelAspectRatio()}
            previewPixelRatio={2}
            tabsIds={[TABS.ADJUST]}
            onBeforeSave={() => false}
            onSave={(editedImageObject, designState) => {
              OnPhotoEditSave(editedImageObject);
            }}
            defaultSavedImageType="jpeg"

            Crop={{
              noPresets: true,
              autoResize: true,
              ratio: 16 / 9,
              ratioTitleKey: 'Landscape',
            }}
            
          />
      );
    }

    function GuideLinesCard() {
      return (
        <Card>
          <CardContent>
            <Typography variant='h4' gutterBottom>
              Richtlijnen
            </Typography>
            <Typography variant="caption" gutterBottom>
              <ul>
                <li>Zorg er voor dat de auto volledig in beeld is en voorkom dat objecten het beeld van de auto blokkeren.</li>
                <li>Zorg er voor dat het kenteken zichtbaar is. Indien dit niet mogelijk is, voeg nog een foto toe waarbij het kenteken wel zichtbaar is.</li>
                <li>Zorg er voor dat de auto scherp op de foto staat.</li>
                <li>Foto's mogen niet zwaar bewerkt zijn. Kleine aanpassingen aan contrast, kleurbalans of helderheid zijn wel toegestaan.</li>
                <li>Neem geen foto's van auto's op privé terrein zonder toestemming van de eigenaar.</li>
                <li>Indien er personen op de foto staan, maak deze altijd onherkenbaar.</li>
                <li>Plaats geen foto's van te lang geleden. Afhankelijk van de auto en de foto zijn oudere foto's wel toegestaan.</li><br/>
                <li>Voertuigvinder houdt het recht om iedere foto af te wijzen of te verwijderen indien genoodzaakt of gewenst.</li>
              </ul>
            </Typography>
          </CardContent>
        </Card>
      );
    }

    function MetaData() {
      return (
        <Grid container spacing={2}>
          <Grid xs={12} md={4} item>
            <Card>
              <CardContent>
                <img src={editedPhotoData?.imageBase64} style={{ maxHeight: 800, maxWidth: '100%', cursor: 'pointer' }} onClick={() => setEditedPhotoData(undefined)} />
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={12} md={4} item>
            <Card>
              <CardContent>
                <Typography variant='h4' gutterBottom>
                  Gegevens
                </Typography>
                <div style={{marginTop: 16}}>
                  <TextField fullWidth variant="standard" label='Gemaakt op' type='date' value={takenOn} onChange={e => setTakenOn(e.target.value)} />
                </div>
                <div style={{marginTop: 16}}>
                  <TextField fullWidth variant="standard" label='Locatie' value={location} onChange={e => setLocation(e.target.value)} />
                </div>
                <div style={{marginTop: 16}}>
                  <FormControlLabel control={<Checkbox value={isHighwaySpot} onChange={e => setIsHighwaySpot(e.target.checked)} />} label="Snelwegspot" />
                </div>
                <div style={{marginTop: 28}}>
                  {!isUploading ?
                    <Button fullWidth disabled={!AllValuesValid()} onClick={AddNewPhoto} variant="contained">Opslaan</Button> :
                    <CircularProgress />}
                </div>
                <Typography variant="caption" gutterBottom>
                  Spots worden handmatig beoordeeld, het kan dus even duren tot de spot zichtbaar is. Bedankt voor uw geduld.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={12} md={3} item>
            {GuideLinesCard()}
          </Grid>
        </Grid>
      );
    }

    return (
    <>
      <ImageListItem>
        <div className={classes.addTile} onClick={() => FireStoreAuthHelper?.GetUser() == undefined ? FireAuthHelper.SignIn() : undefined}>
          <AddAPhoto className={classes.cameraIcon} />
          <span className={classes.addPhotoTitle}>{user == undefined ? "Log in om een spot toe te voegen" : "Spot toevoegen"}</span>
          {user && <input
            className={classes.photoInput}
            type="file"
            onChange={ChoosePhoto}
           />}
        </div>
      </ImageListItem>
      <Dialog fullScreen open={isOpen}>
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => { setIsOpen(false); ClearInput(); }}
              disabled={isUploading}
              size="large">
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box style={{paddingTop: 80, height: '100%' }} bgcolor={theme => theme.palette.grey[100]}>
          {editedPhotoData == undefined ?
            <Card style={{ height: '100%' }}>{PhotoEditor()}</Card> :
            MetaData()
          }
        </Box>
      </Dialog>
    </>);
}

type savedImageData = {
  name: string;
  extension: string;
  mimeType: string;
  fullName?: string;
  height?: number;
  width?: number;
  imageBase64?: string;
  imageCanvas?: HTMLCanvasElement; // doesn't support quality
  quality?: number;
  cloudimageUrl?: string;
};