import React, { useEffect } from 'react';
import "firebase/compat/storage";
import "firebase/compat/auth";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import "firebase/compat/auth";
import { Box, Card, CardContent, Grid, Link, Theme, Typography } from '@mui/material';
import { VoertuigVinderApi } from 'Communication/VoertuigVinderAPI/Client';
import { MakeSummary, User } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';
import { sortMakeSummariesByName } from 'Helper/MakeHelper';
import FireStoreAuthHelper from 'Helper/FireAuthHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  })
);

export default function ManageMakes() {
  const classes = useStyles();

  const [user, setUser] = React.useState<User | undefined>(FireStoreAuthHelper?.GetUser());
  const [makeSummaries, setMakeSummaries] = React.useState<MakeSummary[]>([]);

  FireStoreAuthHelper.OnUserSet(event => { 
    setUser(event.detail as User);
  });

  useEffect(() => {
    initialize();
  }, [user]);

  async function initialize() {
    if (!user?.isAdmin) {
      return;
    }

    let existingMakes = await VoertuigVinderApi.getMakeSummaries(false);
    setMakeSummaries(sortMakeSummariesByName(existingMakes));
  }

  return (
    <Box>
      <Typography variant="h1">Manage makes</Typography>
      <Grid container spacing={2}>
        <Grid key='add' item xs={6} md={3} lg={2}>
          <Card sx={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography component="div" variant="h5">
                  <Link href='/admin/manage-makes/add'>Add make</Link>
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </Grid>
        {makeSummaries.map(make => {
          return (
            <Grid key={make.name} item xs={6} md={3} lg={2}>
              <Card sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5">
                      <Link href={`/admin/manage-makes/${make.id}/edit`}>{make.name}</Link>
                    </Typography>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  );
}