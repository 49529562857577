import { Box, CircularProgress, Fade, Paper, Typography } from '@mui/material';
import { GetGeneralNumbers } from 'Communication/ModelCommunications';
import { IGeneralNumbers } from 'Communication/models/Statistics';
import { Make, Model } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';
import React, { useEffect } from 'react';

interface IProps {
    makeName?: string;
    modelName?: string;
    make?: Make;
    model?: Model;
    hiddenModelNames?: string[];
    firstRegisteredFilter?: { fromYear: number, toYear: number };
}

export default function GeneralNumbers(props: IProps) {
    const [generalNumbers, setGeneralNumbers] = React.useState<IGeneralNumbers>();
    const { make, model, makeName, modelName, hiddenModelNames, firstRegisteredFilter } = props;

    useEffect(() => {
      initialize();
    }, [makeName, modelName, hiddenModelNames, firstRegisteredFilter]);

    async function initialize() {
        RefreshGeneralNumbers();
    }

    async function RefreshGeneralNumbers() {
      let result = await GetGeneralNumbers(
        make?.name != undefined ? [...(make.rdwSearchTerms ?? []), make.name] : makeName != undefined ? [makeName] : [],
        make?.rdwBlacklistSearchTerms,
        model?.rdwBlacklistSearchTerms != null ? hiddenModelNames?.concat(model.rdwBlacklistSearchTerms) : hiddenModelNames,
        modelName != undefined ? [...(model?.rdwSearchTerms ?? []), model?.name ?? modelName] : [],
        makeName !== undefined ? undefined : 'Personenauto',
        firstRegisteredFilter);

      setGeneralNumbers(result);
    }

    return(
        <>
            <Box component={Paper} p={2} mb={2}>
                <Typography variant="subtitle1">
                    Totaal geregistreerd
                </Typography>

                {generalNumbers !== undefined ?
                    <Typography variant="h2" component="h2">{generalNumbers.imported + generalNumbers.originalDutch}</Typography> :
                    <Fade in timeout={5000}><CircularProgress size={70} /></Fade>}
            </Box>
            <Box component={Paper} p={2} mb={2}>
                <Typography variant="subtitle1">
                    Origineel Nederlands
                </Typography>
                {generalNumbers !== undefined ?
                    <Typography variant="h2" component="h2">{generalNumbers.originalDutch}</Typography> :
                    <Fade in timeout={5000}><CircularProgress size={70} /></Fade>}
            </Box>
            <Box component={Paper} p={2}>
                <Typography variant="subtitle1">
                    Geïmporteerd
                </Typography>
                {generalNumbers !== undefined ?
                    <Typography variant="h2" component="h2">{generalNumbers.imported}</Typography> :
                    <Fade in timeout={5000}><CircularProgress size={70} /></Fade>}
            </Box>
        </>
    );
}