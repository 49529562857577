export default async function ExecuteVehicleQuery(query: string) {
  let completeQuery = 'https://opendata.rdw.nl/resource/m9d7-ebf2.json?' + query;
  
  const response = await fetch(completeQuery, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
  if (response.status !== 400) {
    const content = await response.json();
    return content;
  }
}

export async function ExecuteEngineQuery(query: string) {
  let completeQuery = 'https://opendata.rdw.nl/resource/8ys7-d773.json?' + query;
  
  const response = await fetch(completeQuery, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
  if (response.status !== 400) {
    const content = await response.json();
    return content;
  }
}

export async function ExecuteGearboxQuery(query: string) {
  let completeQuery = 'https://opendata.rdw.nl/resource/r7cw-67gs.json?' + query;
  
  const response = await fetch(completeQuery, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
  if (response.status !== 400) {
    const content = await response.json();
    return content;
  }
}

export async function ExecuteAxleQuery(query: string) {
  let completeQuery = 'https://opendata.rdw.nl/resource/3huj-srit.json?' + query;
  
  const response = await fetch(completeQuery, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
  if (response.status !== 400) {
    const content = await response.json();
    return content;
  }
}

export async function InspectionQuery(query: string) {
  let completeQuery = 'https://opendata.rdw.nl/resource/sgfe-77wx.json?' + query;
  
  const response = await fetch(completeQuery, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
  if (response.status !== 400) {
    const content = await response.json();
    return content;
  }
}

export async function DetectedDefectQuery(query: string) {
  let completeQuery = 'https://opendata.rdw.nl/resource/a34c-vvps.json?' + query;
  
  const response = await fetch(completeQuery, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
  if (response.status !== 400) {
    const content = await response.json();
    return content;
  }
}

export async function DefectDefinitionQuery(query: string) {
  let completeQuery = 'https://opendata.rdw.nl/resource/hx2c-gt7k.json?' + query;
  
  const response = await fetch(completeQuery, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
  if (response.status !== 400) {
    const content = await response.json();
    return content;
  }
}