import { TrimSummary } from "Communication/VoertuigVinderAPI/VoertuigVinderApiClient";

function compareSummariesByName(a: TrimSummary, b: TrimSummary) {
    if (a.name == undefined || b.name == undefined)
        return 0;

    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
}

export function sortTrimSummariesByName(trimSummaries: TrimSummary[]) {
    return (Object.assign([], trimSummaries) as TrimSummary[]).sort(compareSummariesByName);
  }