import React, { useEffect } from 'react';
import { ResponsivePie } from '@nivo/pie'
import { GetNumberRegisteredPerColor } from 'Communication/ModelCommunications';
import { INumberRegisteredPerColor } from 'Communication/models/Statistics';
import { CircularProgress, Fade, Paper, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RdwColorToHex } from 'Helper/ColorHelper';
import { Make, Model } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';

interface IProps {
  makeName: string;
  modelName?: string;
  make?: Make;
  model?: Model;
  hiddenModelNames: string[];
  firstRegisteredFilter: { fromYear: number, toYear: number };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingPlaceholderContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
      minHeight: `calc(430px - ${theme.spacing(4)})`
    },
  })
);

export default function ColorPercentagePieChart(props: IProps) {
    const [numberRegisteredPerColor, setNumberRegisteredPerColor] = React.useState<INumberRegisteredPerColor[]>();
    const [pieData, setPieData] = React.useState<any[]>();
    const { make, model, makeName, modelName, hiddenModelNames, firstRegisteredFilter } = props;
    const classes = useStyles();

    useEffect(() => {
      initialize();
    }, [make, model, makeName, modelName, hiddenModelNames, firstRegisteredFilter]);

    async function initialize() {
        RefreshNumberRegisteredByColorAndYear();
    }

    async function RefreshNumberRegisteredByColorAndYear() {
        let result = await GetNumberRegisteredPerColor(
          [...(make?.rdwSearchTerms ?? []), make?.name ?? makeName],
          modelName != undefined ? [...(model?.rdwSearchTerms ?? []), model?.name ?? modelName] : [],
          model?.rdwBlacklistSearchTerms != undefined ? hiddenModelNames.concat(model.rdwBlacklistSearchTerms) : hiddenModelNames,
          firstRegisteredFilter);
        let resultAsPieDatum = result.map(r => { return { id: r.primaryColor, value: r.numberRegistered, color: 'hsl(244, 70%, 50%)' };})
        setNumberRegisteredPerColor(result);
        setPieData(resultAsPieDatum);
    }

    if (pieData == null)
      return <div className={classes.loadingPlaceholderContainer}><Fade in timeout={5000}><CircularProgress size={120} /></Fade></div>

    return (
        <div style={{ height: 430 }}>
          <ResponsivePie
            data={pieData}
            margin={{ top: 40, right: 48, bottom: 40, left: 48 }}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1 ] ] }}
            colors={d => RdwColorToHex[d.id]}
            arcLabelsSkipAngle={8}
            arcLinkLabelsSkipAngle={2}
            arcLinkLabelsDiagonalLength={10}
            arcLinkLabelsThickness={2}
            arcLinkLabelsStraightLength={4}
            borderWidth={2}
            arcLinkLabel={d => `${d.id.toString().toUpperCase().slice(0, 1)}${d.id.toString().toLowerCase().slice(1)}`}
            arcLabel={d => `${(d.value / (pieData.reduce((sum: number, d: any) => sum + d.value, 0)) * 100).toPrecision(3)}%`}
            arcLinkLabelsColor={d => d.color}
            arcLabelsTextColor={b => b.id === 'ZWART' ? '#bdbdbd' : '#1f1f1f'}
            tooltip={(d: any) => { 
              return <Paper style={{ padding: 4 }}>{d.datum.id?.toString().toUpperCase().slice(0, 1)}{d.datum.id?.toString().toLowerCase().slice(1)}: <b>{d.datum.value}</b></Paper>;
            }}
          />
        </div>
    )
}