export const RdwColorToHex = {
    'ROOD': '#ff1100',
    'ORANJE': '#ff9d00',
    'ZWART': '#1c1c1c',
    'GRIJS': '#7a7a7a',
    'WIT': '#e0e0e0',
    'GEEL': '#fbff00',
    'PAARS': '#cc00ff',
    'BLAUW': '#006eff',
    'GROEN': '#28ed00',
    'BRUIN': '#944300',
    'BEIGE': '#e1c699',
    'ROSE': '#b76e79',
}