import { InspectionQuery, DetectedDefectQuery, DefectDefinitionQuery } from './RdwCommunication';
import { IInspection, IDetectedDefect, IDefectDefinition, IInspectionReport, IInspectionReportDefect } from './models/Inspection';

async function GetInspectionsByRegistration(registration: string): Promise<IInspection[]> {
    let selectQuery = `$select=kenteken as registration, meld_datum_door_keuringsinstantie as executedOn, vervaldatum_keuring as validUntil`;
    let whereQuery = `&$where=kenteken='${registration}'`
    let result: IInspection[] = await InspectionQuery(selectQuery + whereQuery);
    return result
}

async function GetDetectedDefectsByRegistration(registration: string): Promise<IDetectedDefect[]> {
    let selectQuery = `$select=kenteken as registration, gebrek_identificatie as defectDefinitionId, meld_datum_door_keuringsinstantie as detectedOn, aantal_gebreken_geconstateerd as numberOfDefects`;
    let whereQuery = `&$where=kenteken='${registration}'`
    let result: IDetectedDefect[] = await DetectedDefectQuery(selectQuery + whereQuery);
    return result
}

async function GetDefectDefinitionsById(defectDefinitionIds: string[]): Promise<IDefectDefinition[]> {
    let selectQuery = `$select=gebrek_identificatie as id, gebrek_omschrijving as description, gebrek_artikel_nummer as partNumber`;
    let whereQuery = `&$where=gebrek_identificatie in ('${defectDefinitionIds.join(`','`)}')`
    let result: IDefectDefinition[] = await DefectDefinitionQuery(selectQuery + whereQuery);
    return result
}

export async function GetInspectionReports(registration: string): Promise<IInspectionReport[]> {
    let inspections = await GetInspectionsByRegistration(registration);
    let detectedDefects = await GetDetectedDefectsByRegistration(registration);

    if ((inspections == undefined || inspections.length == 0)) {
        return [];
    }

    let defectDefinitionIds = detectedDefects.map(dd => dd.defectDefinitionId);
    let defectDefinitions = await GetDefectDefinitionsById(defectDefinitionIds);

    let inspectionReports: IInspectionReport[] = inspections.map(i => ({ executedOn: i.executedOn, validUntil: i.validUntil, detectedDefects: [] } as IInspectionReport));

    detectedDefects.forEach(detectedDefect => {
        let defectDefinition = defectDefinitions.find(dd => dd.id == detectedDefect.defectDefinitionId);
        let inspectionReportDefect: IInspectionReportDefect = {
            description: defectDefinition?.description.replaceAll(defectDefinition.partNumber, '') ?? 'Onbekende fout',
            numberOfDefects: detectedDefect.numberOfDefects,
        };

        let inspectionReport = inspectionReports.find(ir => ir.executedOn == detectedDefect.detectedOn);
        if (inspectionReport != undefined) {
            inspectionReport.detectedDefects.push(inspectionReportDefect);
        }
    });

    return inspectionReports;
}