import { ModelSummary } from "Communication/VoertuigVinderAPI/VoertuigVinderApiClient";

function compareSummariesByName(a: ModelSummary, b: ModelSummary) {
    if (a.name == undefined || b.name == undefined)
        return 0;

    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
}

export function sortModelSummariesByName(makeSummaries: ModelSummary[]) {
    return (Object.assign([], makeSummaries) as ModelSummary[]).sort(compareSummariesByName);
  }