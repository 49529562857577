import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import "firebase/compat/firestore";
import { GetVehicleListBase, GetGeneralNumbers } from 'Communication/ModelCommunications';
import { IRdwVehicleListBase } from 'Communication/models/Vehicle';
import {
  CircularProgress,
  Divider,
  Fade,
  Icon,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import Pagination from '@mui/lab/Pagination/Pagination';
import { Instagram, Photo as PhotoIcon } from '@mui/icons-material';
import { FormatModelName, FormatRegistration } from 'Helper/FormatterHelper';
import { Make, Model, Vehicle } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';
import { VoertuigVinderApi } from 'Communication/VoertuigVinderAPI/Client';

interface IProps {
    make?: Make;
    model?: Model;
    makeName: string;
    modelName?: string;
    hiddenModelNames: string[];
    firstRegisteredFilter: { fromYear: number, toYear: number }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
        height: 430,
        overflow: 'hidden'
    },
    filterBar: {
        width: `calc(100% - ${theme.spacing(2)})`,
        height: 40,
        padding: `${theme.spacing(1)} ${theme.spacing(1)} 0 ${theme.spacing(1)}`,
        position: 'sticky',
        backgroundColor: theme.palette.background.paper,
        zIndex: 1,
        top: 0,
    },
    filterBarDivider: {
        margin: `${theme.spacing(1)} -${theme.spacing(1)} 0`,
    },
    loadingPlaceholderContainer: {
      width: '100%',
      height: '80%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    vehicleList: {
        width: '100%',
        height: `calc(100% - 64px)`,
        overflow: 'auto'
    },
    rightInfoContainer: {
      textAlign: 'right'
    },
    instagram: {
      marginBottom: -4,
      '& svg': {
        height: '0.8em',
        color: '#8a3ab9',
      }
    },
    photo: {
      marginBottom: -4,
      '& svg': {
        height: '0.8em',
        color: '#50a5ff',
      }
    }
  })
);

const pageSize = 50;
export default function RegisteredVehicleList(props: IProps) {
    const [rdwVehicles, setRdwVehicles] = React.useState<IRdwVehicleListBase[]>([]);
    const [totalNumberOfVehicles, setTotalNumberOfVehicles] = React.useState(0);
    const [vehicles, setVehicles] = React.useState<Vehicle[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const { make, model, makeName, modelName, hiddenModelNames, firstRegisteredFilter } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useNavigate();
    const searchParams = new URLSearchParams(document.location.search);
    const page: number = +(searchParams.get('page') ?? '1');
    

    useEffect(() => {
       initialize();
    }, [make, model, makeName, modelName, hiddenModelNames, firstRegisteredFilter]);

    async function initialize() {
      setIsLoading(true);
      setCurrentPage(page);
      let refreshVehiclesTask = RefreshVehicles();
      let refreshTotalNumberOfVehiclesTask = RefreshTotalNumberOfVehicles();
      await Promise.all([refreshVehiclesTask, refreshTotalNumberOfVehiclesTask]);
      setIsLoading(false);
    }

    async function RefreshVehicles(forcePage?: number) {
      let result = await GetVehicleListBase(
        make?.name != undefined ? [...(make.rdwSearchTerms ?? []), make.name] : [makeName],
        'datum_eerste_tenaamstelling_in_nederland',
        false,
        pageSize,
        forcePage === undefined ? currentPage : forcePage,
        model?.rdwBlacklistSearchTerms != undefined ? hiddenModelNames.concat(model?.rdwBlacklistSearchTerms) : hiddenModelNames,
        modelName != undefined ? [...(model?.rdwSearchTerms ?? []), model?.name ?? modelName] : [],
        firstRegisteredFilter);
      let registrationsList = result.map(r => r.kenteken);
      if (registrationsList?.length > 0) {
        setVehicles(await VoertuigVinderApi.getVehiclesByRegistrations(registrationsList.join(',')));
      }
      
      setRdwVehicles(result);

      if (forcePage != undefined) {
        searchParams.set('page', forcePage?.toString() ?? '1');
        history({ 
          pathname: window.location.pathname,
          search: searchParams.toString()
        });
      }
      
    }

    async function RefreshTotalNumberOfVehicles() {
      let generalNumbers = await GetGeneralNumbers(
        make?.name != undefined ? [...(make.rdwSearchTerms ?? []), make.name] : [makeName],
        make?.rdwBlacklistSearchTerms,
        model?.rdwBlacklistSearchTerms != null ? hiddenModelNames?.concat(model.rdwBlacklistSearchTerms) : hiddenModelNames,
        modelName != undefined ? [...(model?.rdwSearchTerms ?? []), model?.name ?? modelName] : [],
        undefined,
        firstRegisteredFilter);
      setTotalNumberOfVehicles(generalNumbers.imported + generalNumbers.originalDutch)
      return;
    }

    async function onPageChange(event: React.ChangeEvent<unknown>, newPage: number){
        setIsLoading(true);
        setCurrentPage(newPage);
        await RefreshVehicles(newPage);
        setIsLoading(false);
    }

    return(
      <div className={classes.container}>
          <div className={classes.filterBar}>
              <Pagination page={currentPage} onChange={onPageChange} count={Math.ceil(totalNumberOfVehicles / pageSize)} siblingCount={useMediaQuery(theme.breakpoints.up('xl')) ? 5 : 2} />
              <Divider className={classes.filterBarDivider} />
          </div>
          {!isLoading && <List className={classes.vehicleList}>
              {rdwVehicles.map(rdwVehicle => {
                  let vehicle = vehicles.find(fv => fv.registration === rdwVehicle.kenteken);
                  return(
                      <ListItem key={rdwVehicle.kenteken}>
                          <ListItemText
                            primary={<Link href={`/vehicle/NLD/${rdwVehicle.kenteken}`}>{FormatRegistration(rdwVehicle.kenteken)}</Link>}
                            secondary={
                              <>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  style={{ display: 'inline' }}
                                  color="textPrimary"
                                >
                                  {rdwVehicle.merk}
                                </Typography>
                                {` ${FormatModelName(rdwVehicle.merk, rdwVehicle.handelsbenaming)}`}
                                {(vehicle?.instagram != undefined && vehicle?.instagram !== '') && <Icon className={classes.instagram}><Instagram /></Icon>}
                                {(vehicle?.vehicleSpots != undefined && vehicle.vehicleSpots.length > 0) && <Icon className={classes.photo}><PhotoIcon /></Icon>}
                              </>
                            }
                          />
                          <ListItemSecondaryAction>
                              <div className={classes.rightInfoContainer}>
                                  <div>Eerste: <b>{moment(rdwVehicle.datum_eerste_toelating).format('L')}</b></div>
                                  <div>Eerste NL: <b>{moment(rdwVehicle.datum_eerste_tenaamstelling_in_nederland).format('L')}</b></div>
                                  <div>Laatste: <b>{moment(rdwVehicle.datum_tenaamstelling).format('L')}</b></div>
                              </div>
                          </ListItemSecondaryAction>
                      </ListItem>
                  );
              })}
          </List>}
          {isLoading && <div className={classes.loadingPlaceholderContainer}><Fade in timeout={5000}><CircularProgress size={80} /></Fade></div>}
      </div>
    );
}