import { INumberRegistrationInNetherlands, INumberFirstRegistration, INumberOfRegisteredColorByYearAndColor, INumberRegisteredPerColor, IGeneralNumbers, INumberRegisteredModelName } from './models/Statistics';
import ExecuteQuery from './RdwCommunication';
import moment from 'moment';
import { IRdwVehicleListBase } from './models/Vehicle';

function getWhereQuery(makes: string[] = [], hiddenMakeNames: string[] = [], hiddenModelNames: string[] = [], models: string[] = [], voertuigType?: string, firstRegisteredFilter?: { fromYear: number, toYear: number }): string {
    let whereParts: string[] = [];

    if (makes.filter(hm => hm != '').length > 0)
        whereParts.push(`(merk='${makes.map(m => m.replaceAll('&', '%26').replaceAll('/', '%2F').toUpperCase()).filter(hm => hm != '').join(`' OR merk='`)}')`)

    if (voertuigType !== undefined)
        whereParts.push(`voertuigsoort='${voertuigType}'`);

    if (firstRegisteredFilter !== undefined && firstRegisteredFilter.fromYear !== 0)
        whereParts.push(`datum_eerste_toelating >= '${firstRegisteredFilter.fromYear}0000'`);

    if (firstRegisteredFilter !== undefined && firstRegisteredFilter.toYear !== 0)
        whereParts.push(`datum_eerste_toelating <= '${firstRegisteredFilter.toYear}9999'`);

    hiddenMakeNames.filter(hm => hm != '').forEach(hiddenMakeName => {
        whereParts.push(`merk != '${hiddenMakeName}'`);
    });

    if (models.filter(hm => hm != '').length > 0)
        whereParts.push(`(handelsbenaming LIKE'%25${models
            .filter(hm => hm != '')
            .map(m => m.replaceAll('&', '%26').replaceAll('/', '%2F').toUpperCase())
            .join(`%25' OR handelsbenaming LIKE'%25`)}%25')`)

    if (hiddenModelNames.filter(hm => hm != '').length > 0)
        whereParts.push(`(handelsbenaming NOT LIKE'%25${hiddenModelNames
            .filter(hm => hm != '')
            .map(m => m.replaceAll('&', '%26').replaceAll('/', '%2F').toUpperCase())
            .join(`%25' AND handelsbenaming NOT LIKE'%25`)}%25')`)

    return `&$where=` + whereParts.join(' and ');
}

export async function GetNumberFirstRegisteredGroupedByYear(makes: string[], models: string[], hiddenModelNames: string[], firstRegisteredFilter: { fromYear: number, toYear: number }): Promise<INumberFirstRegistration[]> {
    let queryYearCase: string[] = [];

    for (let year = firstRegisteredFilter.fromYear !== 0 ? firstRegisteredFilter.fromYear - 1 : 1894; year <= (firstRegisteredFilter.toYear !== 0 ? firstRegisteredFilter.toYear : moment().year()); year++) {
        queryYearCase.push(`datum_eerste_toelating between ${year}0000 and ${year}9999, ${year}`);
    }

    let selectQuery = `$select=case(${queryYearCase.join(',')}) as registrationYear,sum(case(datum_eerste_toelating != datum_eerste_tenaamstelling_in_nederland, 1, datum_eerste_tenaamstelling_in_nederland is null AND datum_eerste_toelating is not null, 1)) as inOtherCountry,sum(case(datum_eerste_toelating=datum_eerste_tenaamstelling_in_nederland,1)) as inNetherlands`
    let whereQuery = getWhereQuery(makes, [], hiddenModelNames, models, undefined, firstRegisteredFilter);
    let groupQuery = `&$group=registrationYear`;
    let result: INumberFirstRegistration[] = await ExecuteQuery(selectQuery + whereQuery + groupQuery);

    result.forEach(element => {
        element.inNetherlands = +(element.inNetherlands ?? 0);
        element.inOtherCountry = +(element.inOtherCountry ?? 0);
    });
    return result.sort((n1, n2) => +n1.registrationYear - +n2.registrationYear);;
}

export async function GetNumberRegisteredInNetherlandsGroupedByYear(makes: string[], models: string[], hiddenModelNames: string[], firstRegisteredFilter: { fromYear: number, toYear: number }): Promise<INumberRegistrationInNetherlands[]> {
    let queryYearCase: string[] = [];
    for (let year = 1894; year <= moment().year(); year++) {
        queryYearCase.push(`datum_eerste_tenaamstelling_in_nederland between ${year}0000 and ${year}9999, ${year}`);
    }
    let selectQuery = `$select=case(${queryYearCase.join(',')}) as registrationYearNetherlands,sum(case(datum_eerste_toelating != datum_eerste_tenaamstelling_in_nederland, 1, datum_eerste_tenaamstelling_in_nederland is null AND datum_eerste_toelating is not null, 1)) as imported,sum(case(datum_eerste_toelating=datum_eerste_tenaamstelling_in_nederland,1)) as originalDutch`
    let whereQuery = getWhereQuery(makes, [], hiddenModelNames, models, undefined, firstRegisteredFilter);
    let groupQuery = `&$group=registrationYearNetherlands`;
    let result: INumberRegistrationInNetherlands[] = await ExecuteQuery(selectQuery + whereQuery + groupQuery);
    result.forEach(element => {
        element.imported = +(element.imported ?? 0);
        element.originalDutch = +(element.originalDutch ?? 0);
    });
    return result.sort((n1, n2) => +n1.registrationYearNetherlands - +n2.registrationYearNetherlands);
}

export async function GetNumberOfRegisteredColorByYearAndColor(makes: string[], models: string[], hiddenModelNames: string[], firstRegisteredFilter: { fromYear: number, toYear: number }): Promise<INumberOfRegisteredColorByYearAndColor[]> {
    let queryYearCase: string[] = [];
    for (let year = 1894; year <= moment().year(); year++) {
        queryYearCase.push(`datum_eerste_toelating between ${year}0000 and ${year}9999, ${year}`);
    }
    let selectQuery = `$select=case(${queryYearCase.join(',')}) as registrationYear,count(*) as numberFirstRegistered,eerste_kleur as primaryColor`;
    let whereQuery = getWhereQuery(makes, [], hiddenModelNames, models, undefined, firstRegisteredFilter)
    let groupQuery = `&$group=registrationYear,primaryColor`;
    let result: INumberOfRegisteredColorByYearAndColor[] = await ExecuteQuery(selectQuery + whereQuery + groupQuery);
    result.forEach(element => {
        element.numberFirstRegistered = +(element.numberFirstRegistered ?? 0);
    });
    return result.sort((n1, n2) => +n1.registrationYear - +n2.registrationYear);
}

export async function GetNumberRegisteredPerColor(makes: string[], models: string[], hiddenModelNames: string[], firstRegisteredFilter: { fromYear: number, toYear: number }): Promise<INumberRegisteredPerColor[]> {
    let numberOfRegisteredColorByYearAndColor = await GetNumberOfRegisteredColorByYearAndColor(makes, models, hiddenModelNames, firstRegisteredFilter);
    let numbersRegisteredPerColor: INumberRegisteredPerColor[] = [];
    numberOfRegisteredColorByYearAndColor.forEach(nryc => {
        let colorName = nryc.primaryColor;
        if (['Niet geregistreerd', 'N.v.t.', 'DIVERSEN'].includes(colorName))
            colorName = 'Overige';

        let numberRegisteredPerColor = numbersRegisteredPerColor.find(nrpc => nrpc.primaryColor === colorName);
        if (numberRegisteredPerColor === undefined) {
            numbersRegisteredPerColor.push({ primaryColor: colorName, numberRegistered: nryc.numberFirstRegistered });
        } else {
            numberRegisteredPerColor.numberRegistered += nryc.numberFirstRegistered;
        }
    });

    return numbersRegisteredPerColor;
}

export async function GetNumberRegisteredPerModelName(makes: string[], models: string[], hiddenModelNames: string[], firstRegisteredFilter: { fromYear: number, toYear: number }): Promise<INumberRegisteredModelName[]> {
    let selectQuery = `$select=handelsbenaming as modelName, count(*) as numberRegistered`;
    let whereQuery = getWhereQuery(makes, [], hiddenModelNames, models, undefined, firstRegisteredFilter)
    let groupQuery = `&$group=handelsbenaming&$limit=99999`;
    let result: INumberRegisteredModelName[] = await ExecuteQuery(selectQuery + whereQuery + groupQuery);
    result.forEach(element => {
        element.numberRegistered = +(element.numberRegistered ?? 0);
    });

    return result;
}

export async function GetGeneralNumbers(makes?: string[], hiddenMakeNames?: string[], hiddenModelNames?: string[], modelNames?: string[], voertuigType?: string, firstRegisteredFilter?: { fromYear: number, toYear: number }): Promise<IGeneralNumbers> {
    let selectQuery = `$select=sum(case(datum_eerste_toelating != datum_eerste_tenaamstelling_in_nederland, 1, datum_eerste_tenaamstelling_in_nederland is null AND datum_eerste_toelating is not null, 1)) as imported,sum(case(datum_eerste_toelating=datum_eerste_tenaamstelling_in_nederland OR (datum_eerste_toelating is null AND datum_eerste_tenaamstelling_in_nederland is null),1)) as originalDutch`;
    let whereQuery = getWhereQuery(makes, hiddenMakeNames, hiddenModelNames, modelNames, voertuigType, firstRegisteredFilter)
    let result = await ExecuteQuery(selectQuery + whereQuery);
    let generalNumbers: IGeneralNumbers = result[0];
    generalNumbers.imported = Number.isNaN(+generalNumbers.imported) ? 0 : +generalNumbers.imported;
    generalNumbers.originalDutch = Number.isNaN(+generalNumbers.originalDutch) ? 0 : +generalNumbers.originalDutch;
    return generalNumbers;
}

export async function GetVehicleListBase(makes: string[], orderBy: string, desc: boolean, pageSize: number, page: number, hiddenModelNames?: string[], modelNames?: string[], firstRegisteredFilter?: { fromYear: number, toYear: number }): Promise<IRdwVehicleListBase[]> {
    let selectQuery = `$select=kenteken,merk,handelsbenaming,eerste_kleur,datum_tenaamstelling,datum_eerste_toelating,datum_eerste_tenaamstelling_in_nederland,catalogusprijs`;
    let whereQuery = getWhereQuery(makes, [], hiddenModelNames, modelNames, undefined, firstRegisteredFilter)
    let orderQuery = `&$order=${orderBy} ${desc ? 'DESC' : 'ASC'}`;
    let pagingQuery = `&$limit=${pageSize}&$offset=${pageSize * (page - 1)}`;
    let result = await ExecuteQuery(selectQuery + whereQuery + orderQuery + pagingQuery);
    return result;
}