import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { GetRandomBackgroundUrl } from 'Helper/BackgroundImageHelper';
import { Box, Button, Grid, Input, Theme, Typography } from '@mui/material';
import Seo from 'Components/Seo/Seo';
import RegistrationNl from './RegistrationNL';
import RegistrationInput from 'Components/Header/Components/RegistrationInput';
import AnalyticsHelper from 'Helper/AnalyticsHelper';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      banner: {
        backgroundImage: `linear-gradient(
            to top,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.6)
            ),url(${GetRandomBackgroundUrl()})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'space',
        marginLeft: theme.spacing(-4),
        minHeight: '95vh',
  
        [theme.breakpoints.down('md')]: {
          marginLeft:  theme.spacing(-1),
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft:  theme.spacing(-1),
        },
      },
      kentekenSvg: {
        width: 400,
        height: 100,

        [theme.breakpoints.down('sm')]: {
            width: 300,
            height: 75,
        },
      },
      registrationInput: {
        fontSize: 55,

        [theme.breakpoints.down('sm')]: {
            fontSize: 35,
        },
      }
    })
);

export default function RegistrationSearch() {
    const classes = useStyles();
    const history = useNavigate();
    var inputValue = '';

    function onInputChange(newInputValue: string) {
        inputValue = newInputValue;
    }

    function searchVehicle() {
      if (inputValue.length !== 6)
        return;
      
      AnalyticsHelper.LogEvent("vehicle_seach", { "registration": inputValue });
      history(`/vehicle/NLD/${inputValue}`);
    }

    return (
        <Box>
            <Seo title='Kenteken check' description="Zoek gratis door alle auto's, bussen, vrachtauto's bekend bij het RDW. Vind voertuiggegevens, APK geschiedenis, foto's en meer. De meest complete kenteken check in Nederland." />
            <Box className={classes.banner} sx={{width: {xs: 'calc(100% + 16px)', md: 'calc(100% + 64px)'} }}>
                <Grid                
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="space-evenly"
                  height={'60%'}
                  wrap='nowrap'
                  sx={{pl: {xs: 0.5, sm: 0}}}
                >
                        <Typography variant='h1' fontFamily='Big-Noodle-Titling' sx={{color: 'white', fontWeight: 'bold', fontSize: {xs: '5em', sm: '8em', md: '10em', lg: '13em'}}}>Kenteken check</Typography>
                        <Typography variant='h5' sx={{color: 'white', fontWeight: 'bold', fontSize: {xs: 14, sm: 20, md: 24, lg: 32}}}>Zoek door alle voertuigen op kenteken in Nederland.
                        </Typography>
                        <Typography variant='body2' sx={{color: 'white', fontSize: { xs: 8, sm: 8, md: 10, lg: 16}, px: {sx: 4, sm: 8, md: 0}, textAlign: 'center'}}>
                        Zoek en vind gratis alle voertuigen bekend bij het RDW en meer. Vind voertuiggegevens, APK geschiedenis, foto's en meer.<br/>
                        Daarnaast vind je hier ook gegevens over het merk en het model van het voertuig zoals verkoopcijfers, kleurverdeling en importeer cijfers. 
                        </Typography>
                        <Box sx={{ mt: 4, position: 'relative' }}>
                            <RegistrationNl className={classes.kentekenSvg} />
                            <RegistrationInput placeholder='KENTEKEN' countryCode='NLD' onChange={onInputChange} inputClassName={classes.registrationInput} sx={{ position: 'absolute', top: 8, left: 64, width: { xs: 220, sm: 330 }, height: 50 }} />
                        </Box>
                        <Button variant='contained' sx={{ fontSize: 32, mt: 4, fontFamily: 'Big-Noodle-Titling', px: 4 }} onClick={searchVehicle}>{'Voertuig vinden >'}</Button>
                </Grid>
            </Box>
        </Box>
    );
}