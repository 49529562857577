import { UserProfileVehicleSpot } from "Communication/VoertuigVinderAPI/VoertuigVinderApiClient";
import { IRdwVehicleDetails } from "Communication/models/Vehicle";
import { FormatMakeName, FormatModelName } from "./FormatterHelper";

function compareSummariesByName(a: UserProfileVehicleSpot, b: UserProfileVehicleSpot) {
    if (a.takenOn == undefined || b.takenOn == undefined)
        return 0;

    if ( a.takenOn < b.takenOn ){
      return -1;
    }
    if ( a.takenOn > b.takenOn ){
      return 1;
    }

    if (a.addedOn == undefined || b.addedOn == undefined)
        return 0;

    if ( a.addedOn < b.addedOn ){
      return -1;
    }
    if ( a.addedOn > b.addedOn ){
      return 1;
    }
    return 0;
}

export function sortUserProfileVehicleSpotsByTakenOn(makeSummaries: UserProfileVehicleSpot[]) {
  return (Object.assign([], makeSummaries) as UserProfileVehicleSpot[]).sort(compareSummariesByName);
}

export function GetVehicleName(vehicleSpot?: UserProfileVehicleSpot, rdwVehicleDetails?: IRdwVehicleDetails) {
  let makeName = vehicleSpot?.makeSummary?.name ?? FormatMakeName(rdwVehicleDetails?.merk) ?? "Onbekend";
  let modelName = vehicleSpot?.trimSummary?.name ?? vehicleSpot?.modelSummary?.name ?? FormatModelName(rdwVehicleDetails?.merk, rdwVehicleDetails?.handelsbenaming) ?? "Onbekend";

  if (makeName.length == 0)
    makeName = "Onbekend";

  if (modelName.length == 0)
    modelName = "Onbekend";

  if (makeName == modelName)
      return makeName;

  return `${makeName} ${modelName}`;
}
