import firebase from "firebase/compat/app";

export class BearerTokenApiClient {
    protected async transformOptions(options: RequestInit): Promise<RequestInit> {
        var user = firebase.auth().currentUser;
        if (user == null){
            return Promise.resolve(options);
        }

        var token = await user.getIdToken();
        options.headers = { ...options.headers, authorization: ' Bearer ' + token };
        return Promise.resolve(options);
    }
}