import React from 'react';

interface IProps {
  className?: string;
}

export default function RegistrationNl(props: IProps) {
  let { className } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 400 100">
      {/* Donkerdere gele achtergrond met afgeronde hoeken */}
      <rect width="400" height="100" rx="10" ry="10" fill="#F2A900" stroke="black" strokeWidth="4" />

      {/* Blauwe EU strip met alleen afgeronde hoeken aan de linkerkant */}
      <rect x="2" y="2" width="50" height="96" rx="10" ry="0" fill="#003399" />

      {/* EU sterren, kleiner en verder uit elkaar geplaatst in een grotere cirkel */}
      <g fill="#FFCC00" transform="translate(25, 35)">
        <polygon points="0,-3 1.5,-1.5 3,-1.5 1.5,0 2.5,2.5 0,1.5 -2.5,2.5 -1.5,0 -3,-1.5 -1.5,-1.5" transform="rotate(0) translate(0,-16) scale(0.5)" />
        <polygon points="0,-3 1.5,-1.5 3,-1.5 1.5,0 2.5,2.5 0,1.5 -2.5,2.5 -1.5,0 -3,-1.5 -1.5,-1.5" transform="rotate(30) translate(0,-16) scale(0.5)" />
        <polygon points="0,-3 1.5,-1.5 3,-1.5 1.5,0 2.5,2.5 0,1.5 -2.5,2.5 -1.5,0 -3,-1.5 -1.5,-1.5" transform="rotate(60) translate(0,-16) scale(0.5)" />
        <polygon points="0,-3 1.5,-1.5 3,-1.5 1.5,0 2.5,2.5 0,1.5 -2.5,2.5 -1.5,0 -3,-1.5 -1.5,-1.5" transform="rotate(90) translate(0,-16) scale(0.5)" />
        <polygon points="0,-3 1.5,-1.5 3,-1.5 1.5,0 2.5,2.5 0,1.5 -2.5,2.5 -1.5,0 -3,-1.5 -1.5,-1.5" transform="rotate(120) translate(0,-16) scale(0.5)" />
        <polygon points="0,-3 1.5,-1.5 3,-1.5 1.5,0 2.5,2.5 0,1.5 -2.5,2.5 -1.5,0 -3,-1.5 -1.5,-1.5" transform="rotate(150) translate(0,-16) scale(0.5)" />
        <polygon points="0,-3 1.5,-1.5 3,-1.5 1.5,0 2.5,2.5 0,1.5 -2.5,2.5 -1.5,0 -3,-1.5 -1.5,-1.5" transform="rotate(180) translate(0,-16) scale(0.5)" />
        <polygon points="0,-3 1.5,-1.5 3,-1.5 1.5,0 2.5,2.5 0,1.5 -2.5,2.5 -1.5,0 -3,-1.5 -1.5,-1.5" transform="rotate(210) translate(0,-16) scale(0.5)" />
        <polygon points="0,-3 1.5,-1.5 3,-1.5 1.5,0 2.5,2.5 0,1.5 -2.5,2.5 -1.5,0 -3,-1.5 -1.5,-1.5" transform="rotate(240) translate(0,-16) scale(0.5)" />
        <polygon points="0,-3 1.5,-1.5 3,-1.5 1.5,0 2.5,2.5 0,1.5 -2.5,2.5 -1.5,0 -3,-1.5 -1.5,-1.5" transform="rotate(270) translate(0,-16) scale(0.5)" />
        <polygon points="0,-3 1.5,-1.5 3,-1.5 1.5,0 2.5,2.5 0,1.5 -2.5,2.5 -1.5,0 -3,-1.5 -1.5,-1.5" transform="rotate(300) translate(0,-16) scale(0.5)" />
        <polygon points="0,-3 1.5,-1.5 3,-1.5 1.5,0 2.5,2.5 0,1.5 -2.5,2.5 -1.5,0 -3,-1.5 -1.5,-1.5" transform="rotate(330) translate(0,-16) scale(0.5)" />
      </g>

      {/* NL tekst, kleiner en meer ruimte tussen de sterren en NL */}
      <text x="25" y="85" fontFamily="Arial" fontSize="29" fill="white" textAnchor="middle" letterSpacing="-2">NL</text>
    </svg>
  );
};