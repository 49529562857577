import React, { useEffect } from 'react';
import { BarDatum, BarTooltipProps, ResponsiveBar } from '@nivo/bar'
import { GetNumberRegisteredInNetherlandsGroupedByYear } from 'Communication/ModelCommunications';
import { INumberRegistrationInNetherlands } from 'Communication/models/Statistics';
import { Box, CircularProgress, Fade, Theme, Typography } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Make, Model } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';

interface IProps {
  make?: Make;
  model?: Model,
  makeName: string,
  modelName?: string,
  hiddenModelNames: string[];
  firstRegisteredFilter: { fromYear: number, toYear: number }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingPlaceholderContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
      minHeight: `calc(430px - ${theme.spacing(4)})`
    },
  })
);

export default function NumberRegisteredInNetherlandsGraph(props: IProps) {
    const [numberRegisteredInNetherlands, setNumberRegisteredInNetherlands] = React.useState<BarDatum[]>();
    const { make, model, makeName, modelName, hiddenModelNames, firstRegisteredFilter } = props;
    const classes = useStyles();

    useEffect(() => {
      initialize();
    }, [make, model, makeName, modelName, hiddenModelNames, firstRegisteredFilter]);

    async function initialize() {
        RefreshNumberRegisteredInNetherlands();
    }

    async function RefreshNumberRegisteredInNetherlands() {
      let result = await GetNumberRegisteredInNetherlandsGroupedByYear(
        [...(make?.rdwSearchTerms ?? []), make?.name ?? makeName],
        modelName != undefined ? [...(model?.rdwSearchTerms ?? []), model?.name ?? modelName] : [],
        model?.rdwBlacklistSearchTerms != undefined ? hiddenModelNames.concat(model.rdwBlacklistSearchTerms) : hiddenModelNames,
        firstRegisteredFilter
        );
      setNumberRegisteredInNetherlands(result.map(r => (r as any as BarDatum)));
    }

    if (numberRegisteredInNetherlands == null)
      return <div className={classes.loadingPlaceholderContainer}><Fade in timeout={5000}><CircularProgress size={120} /></Fade></div>

    function renderTooltip(props: BarTooltipProps<BarDatum>) {
      let labelName = '';
      switch (props.id) {
        case 'originalDutch': labelName = 'Origineel Nederlands'; break;
        case 'imported': labelName = 'Geïmporteerd'; break;
      }

      return <span>{labelName}: <b>{props.value}</b></span>
    }

    return (
      <Box height={430} textAlign='center'>
        <Typography variant="h5">Eerste registratie in Nederland</Typography>
        <ResponsiveBar
          data={numberRegisteredInNetherlands}
          keys={['originalDutch', 'imported']}
          indexBy='registrationYearNetherlands'
          padding={0.1}
          labelSkipHeight={10}
          margin={{ top: 24, right: 24, bottom: 88, left: 56 }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 65,
            legend: 'Jaar',
            legendPosition: 'middle',
            legendOffset: 48,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Aantal',
            legendPosition: 'middle',
            legendOffset: -40
        }}
        tooltip={renderTooltip}
      />
    </Box>
  )
}