import React, { useEffect } from 'react';
import "firebase/compat/storage";
import "firebase/compat/auth";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Instagram, YouTube, DoNotDisturbOn, Check } from '@mui/icons-material';
import TikTokIcon from 'Components/Icons/TikTokIcon';
import "firebase/compat/auth";
import { VoertuigVinderApi } from 'Communication/VoertuigVinderAPI/Client';
import { AdminVehicleSocialMedia, User } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Link, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Paper, TextField, Theme, Typography } from '@mui/material';
import FireAuthHelper from 'Helper/FireAuthHelper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    })
);

export default function ApprovePhotos() {
    const [pendingSocialMedia, setPendingSocialMedia] = React.useState<AdminVehicleSocialMedia[]>([]);
    const [denyDialogOpen, setDenyDialogOpen] = React.useState(false);
    const [denyAdminVehicleSocialMedia, setDenyAdminVehicleSocialMedia] = React.useState<AdminVehicleSocialMedia>();
    const [denyReason, setDenyReaseon] = React.useState('');
    const classes = useStyles();

    useEffect(() => {
        initialize();
    }, []);

    async function initialize() {
        await new Promise(resolve => setTimeout(resolve, 500));

        let user = FireAuthHelper.GetUser();
        
        if (!user?.isAdmin) {
          return;
        }

        let result = await VoertuigVinderApi.getSocialMediaForAdmin();
        setPendingSocialMedia(result);
    }

    function getIcon(platform: string) {
        switch (platform.toLowerCase()) {
            case 'instagram': return <Instagram />;
            case 'youtube': return <YouTube />;
            case 'tiktok': return <TikTokIcon />;
            default: return <div></div>;
        }
    }

    function getSocialMediaLink(platform: string, userName: string) {
        switch (platform.toLowerCase()) {
            case 'instagram': return `https://www.instagram.com/${userName}`;
            case 'youtube': return `https://www.youtube.com/@${userName}`;
            case 'tiktok': return `https://www.tiktok.com/@${userName}`;
            default: return '';
        }
    }

    async function approveSocialMedia(vehicleSocialMediaId: string) {
        await VoertuigVinderApi.approveVehicleSocialMediaById(vehicleSocialMediaId);
        setPendingSocialMedia(pendingSocialMedia.filter(ps => ps.vehicleSocialMediaId != vehicleSocialMediaId));
    }

    async function denySocialMedia() {
        await VoertuigVinderApi.denyVehicleSocialMediaById(denyAdminVehicleSocialMedia?.vehicleSocialMediaId!, { reason: denyReason });
        setPendingSocialMedia(pendingSocialMedia.filter(ps => ps.vehicleSocialMediaId != denyAdminVehicleSocialMedia?.vehicleSocialMediaId));

        setDenyDialogOpen(false);
    }

    return (
        <Box>
            <Typography variant="h1">Approve social media</Typography>
            <Paper style={{ marginTop: 16, maxWidth: 600 }}>
                <List>
                    {pendingSocialMedia.map(ps =>
                        <ListItem key={ps.vehicleSocialMediaId}>
                            <ListItemAvatar>
                                <Avatar>
                                    {getIcon(ps.platform!)}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<><Link href={`/vehicle/${ps.vehicleCountryCode}/${ps.vehicleRegistration}`}>{ps.vehicleRegistration}</Link> - <Link href={`/user/profile/${ps.suggestedByUserId}`}>{ps.suggestedByUserName}</Link></>}
                                secondary={<Link target='_blank' href={getSocialMediaLink(ps.platform!, ps.socialMediaAccount!)}>{ps.socialMediaAccount}</Link>}
                            />
                            <ListItemSecondaryAction>
                                <IconButton onClick={() => { setDenyDialogOpen(true); setDenyAdminVehicleSocialMedia(ps); setDenyReaseon('')}}>
                                    <DoNotDisturbOn />
                                </IconButton>
                                <IconButton onClick={() => approveSocialMedia(ps.vehicleSocialMediaId!)} edge="end">
                                    <Check />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )}
                </List>
            </Paper>
            <Dialog open={denyDialogOpen} onClose={() => setDenyDialogOpen(false)}>
                <DialogTitle id="modal-modal-title">Wijs social media account af</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {denyAdminVehicleSocialMedia?.socialMediaAccount}
                    </DialogContentText>
                    <TextField id="standard-basic" autoFocus fullWidth label='Reason' value={denyReason} onChange={e => setDenyReaseon(e.target.value)} variant="standard" />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setDenyDialogOpen(false)}>Annuleren</Button>
                    <Button variant="contained" onClick={denySocialMedia}>Afwijzen</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}