import React from 'react';
import { Helmet } from "react-helmet";

interface IProps {
    title?: string;
    description?: string;
}

export default function Seo(props: IProps) {
    const { title, description } = props;

    return (
        <Helmet>
            {title && <title>{title}</title>}
            <meta name="description" content={description ?? 'Voertuigvinder is DE site om alles te weten te komen over ieder voertuig in Nederland. Zoek een voertuig, vind een voertuig. Op voertuigvinder is het mogelijk om te zoeken op kenteken, merk, model en meer. Niet alleen hebben wij de publieke RDW data, maar wij bouwen ook onze eigen set aan data op die exclusief is voor voertuigvinder.nl'}></meta>
        </Helmet>
    );
}