import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Modal, Fade, Theme, IconButton, Box } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        backgroundcolor: "red"
      }
    },
    img: {
      outline: "none"
    }
  })
);

interface IProps {
    photoId?: string;
    onClose: () => void;
}

export default function PhotoModal(props: IProps) {
    const { photoId, onClose } = props;
    const classes = useStyles();
    
    const [storageRef, setStorageRef] = React.useState<firebase.storage.Reference>();
    const [open, setOpen] = useState(false);
    const [photoUrl, setPhotoUrl] = useState<string>();

    useEffect(() => {
      handlePhotoIdChange();
    }, [photoId]);

    async function handlePhotoIdChange() {
      if (open && photoUrl != undefined && photoId != undefined)
        return;

      if (photoId == undefined){
        setOpen(false);
        return;
      }

      var activeStorageRef = storageRef ?? firebase.storage().ref().child('Vehicles').child('Spots');

      if (storageRef === undefined){
        setStorageRef(activeStorageRef);
      }
      
      setOpen(true);

      let result = await activeStorageRef.child(photoId!).getDownloadURL()
      setPhotoUrl(result);
    }

    return(
      <Modal
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
      >
        <Fade in={open} className={classes.img}>
        <Box
          sx={{ position: 'relative', border: 'none', height: "90%", width: "90%",
            backgroundImage: `url(${photoUrl})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'
           }}>
          <IconButton
            size='large'
            sx={{position: 'absolute', color: 'white', top: 0, right: 0, zIndex: 1000 }}
            onClick={props.onClose}><CloseIcon sx={{fontSize: 48}} /></IconButton>
          
        </Box>
        
        </Fade>
      </Modal>
    );
}