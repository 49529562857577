import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Link, Modal, Paper, Stack, TextField, Theme, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import "firebase/compat/firestore";
import "firebase/compat/auth";
import React, { useEffect } from 'react';
import { Instagram, YouTube } from '@mui/icons-material';
import TikTokIcon from 'Components/Icons/TikTokIcon';
import { v4 } from 'uuid';
import FireStoreAuthHelper from 'Helper/FireAuthHelper';
import { User, Vehicle } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';
import { VoertuigVinderApi } from 'Communication/VoertuigVinderAPI/Client';
import FireAuthHelper from 'Helper/FireAuthHelper';

interface IProps {
    registration: string;
    countryCode: string;
    vehicle?: Vehicle;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    claimModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: theme.spacing(3),
        maxWidth: 600,
    },
    claimButtonBar: {
        paddingTop: theme.spacing(1),
    }
  })
);

export default function SocialMedia(props: IProps) {
    const { countryCode, registration, vehicle } = props;
    const [user, setUser] = React.useState<User | undefined>(FireStoreAuthHelper?.GetUser());
    const [dialogSocialMediaPlatform, setDialogSocialMediaPlatform] = React.useState<string>();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [editedSocialMedia, setEditedSocialMedia] = React.useState<string>('');
    const classes = useStyles();
    const theme = useTheme();
    const isTiny = useMediaQuery(theme.breakpoints.down('sm'));

    FireStoreAuthHelper.OnUserSet(event => setUser(event.detail as User));

    async function updateSocialMedia() {
        let formattedSocialMedia = editedSocialMedia.toLowerCase().trim();

        switch(dialogSocialMediaPlatform) { 
            case 'Instagram': await VoertuigVinderApi.setInstagramAccountByRegistrationAndCountryCode(registration, countryCode, { vehicleSocialMediaId: v4(), accountName: formattedSocialMedia }); break;
            case 'YouTube': await VoertuigVinderApi.setYouTubeAccountByRegistrationAndCountryCode(registration, countryCode,{ vehicleSocialMediaId: v4(), accountName: formattedSocialMedia }); break;
            case 'TikTok': await VoertuigVinderApi.setTikTokAccountByRegistrationAndCountryCode(registration, countryCode,{ vehicleSocialMediaId: v4(), accountName: formattedSocialMedia }); break;
            default: return '';
        }

        setDialogOpen(false);
    }

    type socialMediaPlatforms = 'Instagram' | 'YouTube' | 'TikTok';
    function getSocialMediaName(platform: socialMediaPlatforms): string{
        switch(platform) { 
            case 'Instagram': return vehicle?.instagram || '';
            case 'YouTube': return vehicle?.youTube || '';
            case 'TikTok': return vehicle?.tikTok || '';
            default: return '';
        } 
    }

    function getIconFontSize() {
        if (isTiny) {
            return 'xx-large';
        }
        return 'x-large'
    }

    function getSocialIcon(platform: socialMediaPlatforms, iconColor?: string): React.ReactElement{
        switch(platform) { 
            case 'Instagram': return <Instagram style={{ color: iconColor ?? '#ffff', fontWeight: 'bolder', fontSize: getIconFontSize() }} />;
            case 'YouTube': return <YouTube style={{ color: iconColor ?? '#ffff', fontWeight: 'bolder', fontSize: getIconFontSize() }} />;
            case 'TikTok': return <TikTokIcon style={{ fill: iconColor ?? '#ffff', fontWeight: 'bolder', fontSize: getIconFontSize() }} />;
            default: return <Instagram style={{ color: iconColor ?? '#ffff', fontWeight: 'bolder', fontSize: getIconFontSize() }} />;
         } 
    }

    function getSocialMediaColors(platform: socialMediaPlatforms): React.CSSProperties{
        switch(platform) { 
            case 'Instagram': return { background: 'linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)' };
            case 'YouTube': return { background: '#FF0000' };
            case 'TikTok': return { background: 'linear-gradient(135deg, #69C9D0, #69C9D0, #EE1D52, #EE1D52)' };
            default: return {};
         } 
    }

    function getSocialMediaLink(platform: socialMediaPlatforms, socialMediaName: string) {
        switch (platform) {
            case 'Instagram': return `https://www.instagram.com/${socialMediaName}`;
            case 'YouTube': return `https://www.youtube.com/@${socialMediaName}`;
            case 'TikTok': return `https://www.tiktok.com/@${socialMediaName}`;
            default: return '';
        }
    }

    function renderSocialMediaLink(platform: socialMediaPlatforms) {
        var socialMediaName = getSocialMediaName(platform);

        return(
            <Link href={getSocialMediaLink(platform, socialMediaName)} target='_blank'>
                <IconButton style={getSocialMediaColors(platform)} size='medium' aria-label="Instagram" >
                    {getSocialIcon(platform)}
                </IconButton>
            </Link>
        );
    }

    function onAddSocialMediaLinkClick(platform: socialMediaPlatforms) {
        if (user == undefined) {
            FireAuthHelper.SignIn();
            return;
        }

        setDialogSocialMediaPlatform(platform);
        setEditedSocialMedia(getSocialMediaName(platform));
        setDialogOpen(true);
    }

    function renderAddSocialMediaLink(platform: socialMediaPlatforms) {
        return(
            <Tooltip title={!user ? 'Log in om een account te suggereren' : 'Suggereer een account'}>
                <div>
                    <IconButton style={{ background: '#D8D8D8' }} onClick={() => onAddSocialMediaLinkClick(platform)} size='medium' aria-label={platform} >
                        {getSocialIcon(platform, '#8A8A8A')}
                    </IconButton>
                </div>
            </Tooltip>
        );
    }

    return (
        <>
            <Grid marginBottom={-2} marginTop={1} container spacing={1} direction={isTiny ? 'row' : 'column'} height='100%' style={{minWidth: isTiny ? 200 : 0 }} >
                <Grid xs={4} className={classes.iconContainer} item>{vehicle?.instagram != undefined && vehicle?.instagram !== '' ? renderSocialMediaLink('Instagram') : renderAddSocialMediaLink('Instagram')}</Grid>
                <Grid xs={4} className={classes.iconContainer} item>{vehicle?.youTube != undefined && vehicle?.youTube !== '' ? renderSocialMediaLink('YouTube') : renderAddSocialMediaLink('YouTube')}</Grid>
                <Grid xs={4} className={classes.iconContainer} item>{vehicle?.tikTok != undefined && vehicle?.tikTok !== '' ? renderSocialMediaLink('TikTok') : renderAddSocialMediaLink('TikTok')}</Grid>
            </Grid>

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle id="modal-modal-title">Voeg {dialogSocialMediaPlatform}-account toe</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Als je het {dialogSocialMediaPlatform} account voor dit voertuig kent, laat het ons hier weten! We zullen het zo snel mogelijk controleren en toevoegen.
                    </DialogContentText>
                    <TextField id="standard-basic" autoFocus fullWidth label={`${dialogSocialMediaPlatform} account`} value={editedSocialMedia} onChange={e => setEditedSocialMedia(e.target.value)} variant="standard" />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setDialogOpen(false)}>Annuleren</Button>
                    <Button variant="contained" onClick={updateSocialMedia}>Toevoegen</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}