import React, { useEffect } from 'react';
import "firebase/compat/storage";
import "firebase/compat/auth";
import { Theme, Box, Typography, Grid, Card, CardContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useParams } from "react-router-dom";
import "firebase/compat/auth";
import FireAuthHelper from 'Helper/FireAuthHelper';
import UserGarage from './Components/UserGarage/UserGarage';
import AnalyticsHelper from 'Helper/AnalyticsHelper';
import { User, UserProfile as Profile } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';
import { VoertuigVinderApi } from 'Communication/VoertuigVinderAPI/Client';
import UserSpots from './Components/UserSpots/UserSpots';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  })
);

export default function UserProfile() {
  AnalyticsHelper.LogScreenVisit("UserProfile");
  const [user, setUser] = React.useState<User>();
  const [profile, setProfile] = React.useState<Profile>();
  const [currentUser, setCurrentUser] = React.useState<User>();
  const classes = useStyles();
  let { userId = "" } = useParams();

  useEffect(() => {
    initialize();
  }, [userId]);

  async function initialize() {
    await new Promise(resolve => setTimeout(resolve, 500));

    let currentUser = FireAuthHelper.GetUser();
    setCurrentUser(currentUser);

    let user = await VoertuigVinderApi.getUserById(userId);
    let userProfile = await VoertuigVinderApi.getUserProfileById(userId);
    setUser(user);
    setProfile(userProfile);
  }

  const isProfileOwner = userId === currentUser?.id;

  return (
    <Box pb={6}>
      <Typography variant="h1">{user?.userName}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={6}>
          <Card><CardContent><Typography variant="body1">{profile?.description}</Typography></CardContent></Card>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <UserGarage user={user} isProfileOwner={isProfileOwner} />
        </Grid>
        <Grid item xs={12}>
          <UserSpots user={user} isProfileOwner={isProfileOwner} />
        </Grid>
      </Grid>
    </Box>
  );
}