import React from 'react';
import { SvgIcon } from '@mui/material';

export default function LicensePlateIcon(props) {
	return (
	  <SvgIcon {...props}>
		<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 			viewBox="0 0 1400.000000 400.000000"
 			preserveAspectRatio="xMidYMid meet">
			<g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
			fill="#ffffff" stroke="none">
			<path d="M600 3980 c-41 -11 -118 -41 -170 -67 -81 -40 -109 -62 -195 -148
			-86 -86 -108 -114 -148 -195 -93 -188 -87 -72 -87 -1570 0 -1495 -6 -1384 85
			-1565 103 -206 294 -359 519 -415 79 -20 123 -20 6396 -20 6273 0 6317 0 6396
			20 286 72 511 297 584 584 20 77 20 110 20 1396 0 1498 6 1382 -87 1570 -40
			81 -62 109 -148 195 -86 86 -114 108 -195 148 -52 26 -131 56 -174 67 -79 20
			-131 20 -6400 19 -6204 0 -6322 0 -6396 -19z m12635 -631 c50 -14 100 -64 114
			-114 15 -56 15 -2414 0 -2470 -14 -50 -64 -100 -114 -114 -57 -16 -12413 -16
			-12470 0 -50 14 -100 64 -114 114 -15 55 -15 2414 0 2468 14 50 55 95 104 112
			34 12 922 14 6240 15 4471 0 6211 -3 6240 -11z"/>
			<path d="M6155 2910 c-114 -24 -192 -67 -269 -150 -137 -149 -194 -349 -203
			-725 -8 -331 21 -531 102 -700 91 -189 243 -290 456 -302 348 -21 545 164 620
			584 18 99 22 157 22 358 0 257 -16 390 -63 535 -72 218 -211 356 -397 395 -73
			16 -203 18 -268 5z m191 -319 c67 -30 109 -109 135 -251 19 -102 19 -613 0
			-725 -25 -150 -64 -224 -135 -256 -53 -24 -80 -24 -130 1 -117 57 -151 214
			-144 671 6 367 27 467 113 536 58 46 99 52 161 24z"/>
			<path d="M7561 2905 c-187 -42 -319 -175 -390 -392 -98 -300 -91 -865 14
			-1133 68 -173 205 -294 377 -335 81 -19 216 -19 297 0 184 44 325 193 391 414
			75 254 80 728 10 996 -59 223 -182 375 -356 436 -79 27 -253 35 -343 14z m191
			-307 c82 -24 138 -133 158 -305 13 -115 13 -525 0 -633 -22 -184 -75 -286
			-161 -312 -78 -23 -160 24 -198 113 -48 111 -55 173 -56 499 0 319 7 401 47
			507 25 68 53 102 102 124 49 22 57 23 108 7z"/>
			<path d="M1725 2893 c-3 -10 -163 -421 -356 -914 -192 -493 -349 -901 -349
			-908 0 -8 55 -11 207 -9 l207 3 76 205 75 205 355 0 355 0 80 -208 80 -207
			414 2 414 3 73 199 c40 109 76 202 81 207 5 5 163 8 358 7 l350 -3 79 -207 80
			-208 213 0 c117 0 213 3 213 7 0 5 -165 421 -367 925 l-367 918 -207 0 -208 0
			-59 -152 c-33 -84 -191 -490 -352 -903 -161 -412 -293 -751 -295 -753 -1 -1
			-166 405 -365 903 l-363 905 -208 0 c-188 0 -207 -2 -214 -17z m334 -790 l109
			-293 -230 0 c-180 0 -229 3 -226 13 148 413 223 607 230 591 4 -11 57 -151
			117 -311z m1951 -285 c0 -4 -101 -8 -225 -8 -124 0 -225 2 -225 5 0 2 45 129
			101 282 56 153 106 291 112 307 9 26 19 4 123 -274 63 -167 114 -307 114 -312z"/>
			<path d="M9975 2893 c-3 -10 -163 -421 -356 -914 -192 -493 -349 -901 -349
			-908 0 -8 55 -11 207 -9 l207 3 76 205 75 205 355 0 355 0 80 -208 80 -207
			414 2 414 3 73 199 c40 109 76 202 81 207 5 5 163 8 358 7 l350 -3 79 -207 80
			-208 213 0 c117 0 213 3 213 7 0 5 -165 421 -367 925 l-367 918 -207 0 -208 0
			-59 -152 c-33 -84 -191 -490 -352 -903 -161 -412 -293 -751 -295 -753 -1 -1
			-166 405 -365 903 l-363 905 -208 0 c-188 0 -207 -2 -214 -17z m333 -790 l110
			-293 -230 0 c-180 0 -229 3 -226 13 143 398 223 606 229 590 4 -10 56 -150
			117 -310z m1952 -285 c0 -4 -101 -8 -225 -8 -124 0 -225 2 -225 5 0 2 45 129
			101 282 56 153 106 291 112 307 9 26 19 4 123 -274 63 -167 114 -307 114 -312z"/>
			<path d="M4860 1735 l0 -185 355 0 355 0 0 185 0 185 -355 0 -355 0 0 -185z"/>
			<path d="M8560 1735 l0 -185 355 0 355 0 0 185 0 185 -355 0 -355 0 0 -185z"/>
			</g>
		</svg>
	  </SvgIcon>
	);
}