import { Box, Collapse, Paper, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Make, Model } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';
import React, { useEffect } from 'react';

interface IProps {
    make?: Make;
    model?: Model;
    isModelView?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    collapsable: {
        position: 'relative',
        '&:after': {
            content: '""',
            position : 'absolute',
            zIndex: 1,
            bottom: 0,
            left: 0,
            pointerEvents: 'none',
            backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 1) 90%)',
            width: '100%',
            height: '4em',
        }
    },
    readMoreLink: {
      textDecoration: 'underline',
      cursor: 'pointer'
    },
  })
);

export default function Description(props: IProps) {
    const { make, model, isModelView } = props;
    const classes = useStyles();
    const [ isOpen, setIsOpen ] = React.useState(false);

    useEffect(() => {
    }, [make, model]);

    if (model != undefined && isModelView) {
        return (
            <div>
                {model.description && model.description.length > 0 && <Box component={Paper} p={2} mb={2} display='flex' alignItems='center' flexDirection='column'>
                    <Collapse className={!isOpen ? classes.collapsable : ''} collapsedSize={96} in={isOpen}>
                        <div dangerouslySetInnerHTML={{ __html: model.description.replaceAll('\n', '<br />')}}></div>
                    </Collapse>
                    {!isOpen && <a onClick={() => setIsOpen(true)} className={classes.readMoreLink}>Lees meer</a>}
                </Box>}
            </div>
        );
    }

    if (make != undefined && !isModelView) {
        return (
            <div>
                {make.description && make.description.length > 0 && <Box component={Paper} p={2} mb={2} display='flex' alignItems='center' flexDirection='column'>
                    <Collapse className={!isOpen ? classes.collapsable : ''} collapsedSize={96} in={isOpen}>
                        <div dangerouslySetInnerHTML={{ __html: make.description.replaceAll('\n', '<br />')}}></div>
                    </Collapse>
                    {!isOpen && <a onClick={() => setIsOpen(true)} className={classes.readMoreLink}>Lees meer</a>}
                </Box>}
            </div>
        )
    }

    return <div></div>
}