import { createTheme, adaptV4Theme } from "@mui/material";
import { deepOrange, blue } from "@mui/material/colors";

export function GetTheme(){
    const theme = createTheme(adaptV4Theme({
        palette: {
          primary: {
            main: deepOrange[500],
          },
          secondary: {
            main: blue[500],
          },
        },
        typography:{
          h1: {
            fontSize: '6em',
            fontWeight: 500
          },
          h2: {
            fontWeight: 500
          },
          h3: {
            fontWeight: 500
          }
        },
      }));
    return theme;
}