const possibleBackgroundUrls = [
    "https://firebasestorage.googleapis.com/v0/b/voertuigvinder.appspot.com/o/Decoration%2Fferrari-interior.JPG?alt=media&token=519a1f95-8c05-4e95-86a7-0bdb859244d3",
    "https://firebasestorage.googleapis.com/v0/b/voertuigvinder.appspot.com/o/Decoration%2Fbmw-hood.jpg?alt=media&token=04e137d0-e603-4560-b6f2-5f23c909e089",
    "https://firebasestorage.googleapis.com/v0/b/voertuigvinder.appspot.com/o/Decoration%2Ff40-through-wing.jpg?alt=media&token=287c7209-c426-4460-b566-63f1bdea257e",
    "https://firebasestorage.googleapis.com/v0/b/voertuigvinder.appspot.com/o/Decoration%2Fsl300-engine.JPG?alt=media&token=653a8889-1c46-4257-bc0e-48bf5578b124",
    "https://firebasestorage.googleapis.com/v0/b/voertuigvinder.appspot.com/o/Decoration%2F300sl-interior.JPG?alt=media&token=96a48402-0f21-4ddc-b5d2-a0dc14742cdd",
    "https://firebasestorage.googleapis.com/v0/b/voertuigvinder.appspot.com/o/Decoration%2Flucid-air-interior.JPG?alt=media&token=081fc463-083f-4810-81c5-91c3d38fee82",
]

export function GetRandomBackgroundUrl() {
    return possibleBackgroundUrls[Math.random() * possibleBackgroundUrls.length | 0];
}