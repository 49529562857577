import React, { ChangeEvent } from 'react';
import "firebase/compat/analytics";
import { SxProps, TextField, Theme } from '@mui/material';
import { useNavigate } from "react-router-dom";
import AnalyticsHelper from 'Helper/AnalyticsHelper';
import { FormatRegistration } from 'Helper/FormatterHelper';

interface IProps {
    countryCode: string;
    className?: string;
    inputClassName?: string;
    sx?: SxProps<Theme>;
    fontSize?: number | string;
    placeholder?: string;
    onChange?: (newInputValue: string) => void;
}

export default function RegistrationInput(props: IProps) {
    const [inputValue, setInputValue] = React.useState('');
    const [simpleInputValue, setSimpleInputValue] = React.useState('');
    let { countryCode, className, sx, inputClassName, placeholder, onChange } = props;

    const history = useNavigate();
    function onInputKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.key.toLocaleLowerCase().includes('enter')) {
            logVehicleSearch();
            history(`/vehicle/${countryCode}/${simpleInputValue}`);
        }
    }

    function onInputChange(newInputValue: string) {
        let modifiedValue = FormatRegistration(newInputValue);
        modifiedValue = modifiedValue.substring(0, Math.min(8, modifiedValue.length));
        let simplifiedValue = modifiedValue.replace(/[^A-Z0-9\s]/g,'');
        setInputValue(modifiedValue);
        setSimpleInputValue(simplifiedValue);

        if (onChange !== undefined) {
            onChange(simplifiedValue);
        }
    }

    function logVehicleSearch() {
        AnalyticsHelper.LogEvent("vehicle_seach", { "registration": inputValue });
    }

    return(
        <TextField inputProps={{ inputMode: 'search', className: inputClassName, style: {fontFamily: countryCode == "NLD" ? "kenteken" : "unset" }}} className={className} sx={sx} variant="standard" placeholder={placeholder ?? 'Kenteken'} value={inputValue} onChange={e => onInputChange(e.target.value)} onKeyDown={onInputKeyDown} />
    );
}