import React, { useEffect } from 'react';
import { alpha, Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { AppBar, IconButton, Link, ListItemText, Menu, MenuItem, Select, Toolbar } from '@mui/material';
import LicensePlateIcon from 'Components/Icons/LicensePlateIcon';
import RegistrationAutocomplete from '../RegistrationAutocomplete/RegistrationAutocomplete';
import { AccountCircle } from '@mui/icons-material';
import FireAuthHelper from 'Helper/FireAuthHelper';
import "firebase/compat/auth";
import { User } from "Communication/VoertuigVinderAPI/VoertuigVinderApiClient";
import countries, { getCountriesSortedByDutchName, ICountry } from 'Helper/CountryHelper';
import RegistrationInput from './Components/RegistrationInput';
import FireStoreAuthHelper from 'Helper/FireAuthHelper';

const logoSmall = 'https://firebasestorage.googleapis.com/v0/b/voertuigvinder.appspot.com/o/Logo%2Fvv_header_icon_white.png?alt=media&token=50123f80-ee3c-412d-b7a4-787b0f689912';
const logoLarge = 'https://firebasestorage.googleapis.com/v0/b/voertuigvinder.appspot.com/o/Logo%2Fvv_header_white.png?alt=media&token=48f92bd5-b542-4bbb-8c46-ee6d012ac5ab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      padding: 0,
      position: 'fixed',
      width: '100%',
      zIndex: 100,
      height: 64
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'block',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      minWidth: 40,

      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(1),
      },
    },
    search: {
      maxWidth: '90%',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(1),
      maxHeight: 32,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
        maxWidth: 350,
      },
    },
    plateIconContainer: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    plateIcon: {
      pointerEvents: 'none',
      fontSize: 64,
      marginRight: theme.spacing(1),
    },
    countrySelect: {
      display: 'flex',
      border: 'none',
      margin: theme.spacing(1, 0, 1, 1),
      fontSize: '14px',
      height: 32,
      alignItems: 'end',
      '& svg': {
        display: 'none'
      },
    },
    countrySelectSelect: {
      paddingRight: '0 !important',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: 0,
      transition: theme.transitions.create('width'),
      [theme.breakpoints.up('md')]: {
        width: 230,
      },
      '& input': {
        paddingLeft: '4px !important'
      }
    },
    accountMenuButton: {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      '& svg': {
        fontSize: 40
      },
      '& img': {
        height: 40,
        borderRadius: 25,
      }
    }
  }),
);

export default function PrimarySearchAppBar() {
  const classes = useStyles();
  const [user, setUser] = React.useState<User | undefined>(FireStoreAuthHelper?.GetUser());
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [countrySelectValue, setCountrySelectValue] = React.useState<string>('NLD');
  const [sortedCountries, setSortedCountries] = React.useState<ICountry[]>([]);

  FireStoreAuthHelper.OnUserSet(event => { 
    setUser(event.detail as User);
  });

  useEffect(() => {
    initialize();
  }, []);

  async function initialize() {
    setSortedCountries(getCountriesSortedByDutchName().filter(c => c.ilpcc != undefined));
  }

  let isOnRegistrationSearch = window.location.pathname === '/kenteken-vinden';

  return (
    <AppBar className={classes.appBar}>
      <Toolbar sx={{minHeight: 64}}>
        <Link className={classes.title} underline='none' href="/" sx={theme => ({ 
          backgroundSize: 'contain',
          backgroundImage: { xs: `url("${isOnRegistrationSearch ? logoLarge : logoSmall}")`, md: `url("${logoLarge}")` },
          width: { xs: isOnRegistrationSearch ? 352 : 40, md: 352 },
          maxWidth: { xs: '100%', sm: 352 },
          height: { xs: isOnRegistrationSearch ? `calc(100% - ${theme.spacing(2)})` : 40, md: `calc(100% - ${theme.spacing(2)})` }})} />
        {!isOnRegistrationSearch && <div style={{ flexGrow: 1 }}>
          <div className={classes.search}>
            <div className={classes.plateIconContainer}>
              <LicensePlateIcon className={classes.plateIcon} />
              <Select
                variant='standard'
                className={classes.countrySelect}
                SelectDisplayProps={{ className: classes.countrySelectSelect }}
                renderValue={(selected) => countries.find(c => c.iso === selected)?.ilpcc}
                onChange={e => setCountrySelectValue(e.target.value)}
                value={countrySelectValue}>
                {sortedCountries.map((country) => (
                  <MenuItem key={country.ilpcc} value={country.iso}>
                    <ListItemText primary={`${country.ilpcc}  - ${country.dutchName}`} />
                  </MenuItem>
                ))}
              </Select>
            </div>
            {
              countrySelectValue === 'NLD' ?
                <RegistrationAutocomplete className={classes.inputInput} /> :
                <RegistrationInput countryCode={countrySelectValue} className={classes.inputInput} />
            }
            
          </div>
        </div>}
        {!isOnRegistrationSearch && <div>
          <IconButton
            className={classes.accountMenuButton}
            onClick={e => setAccountMenuAnchorEl(e.currentTarget)}
            color="inherit"
            size="large">
            {Boolean(user) ? <img src={user?.photoUrl ?? ''} /> : <AccountCircle />}
          </IconButton>
          <Menu
            anchorEl={accountMenuAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(accountMenuAnchorEl)}
            onClose={() => setAccountMenuAnchorEl(null)}
          >
            {(user?.isAdmin ?? false) && <MenuItem><Link href={'/admin'}>Admin</Link></MenuItem>}
            {Boolean(user) && <MenuItem><Link href={`/user/profile/${user?.id}`}>Profiel</Link></MenuItem>}
            {!Boolean(user) ?
              <MenuItem onClick={() => { setAccountMenuAnchorEl(null); FireAuthHelper.SignIn() }}>Log in</MenuItem> :
              <MenuItem onClick={() => { setAccountMenuAnchorEl(null); FireAuthHelper.SignOut() }}>Log uit</MenuItem>}
          </Menu>
        </div>}
      </Toolbar>
    </AppBar>
  );
}
