import { ExecuteGearboxQuery } from './RdwCommunication';
import { IGearbox } from './models/Gearbox';

export async function GetGearboxDetailsByEegOrEuCode(EuTypeInspectionCode: string, eegImplementationCode: string): Promise<IGearbox[]> {
    let selectQuery = `$select=eu_type_goedkeuringssleutel as EuTypeInspectionCode, eeg_uitvoeringscode as eegImplementationCode, type_versnellingsbak as type, aantal_versnellingen_ondergrens as minGearCount, aantal_versnellingen_bovengrens as maxGearCount`;
    let euWhereQuery = `&$where=eu_type_goedkeuringssleutel='${EuTypeInspectionCode}'`;
    let euResult: IGearbox[] = await ExecuteGearboxQuery(selectQuery + euWhereQuery);
    if (euResult.length > 0 ) {
        euResult.forEach(element => {
            element.minGearCount = +element.minGearCount;
            element.maxGearCount = +element.maxGearCount;
        });
    
        return euResult;
    }


    let eegWhereQuery = `&$where=eeg_uitvoeringscode='${eegImplementationCode}'`;
    let eegResult: IGearbox[] = await ExecuteGearboxQuery(selectQuery + eegWhereQuery);
    eegResult.forEach(element => {
        element.minGearCount = +element.minGearCount;
        element.maxGearCount = +element.maxGearCount;
    });

    return eegResult;
}