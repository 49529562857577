import { MakeSummary } from "Communication/VoertuigVinderAPI/VoertuigVinderApiClient";

function compareSummariesByName(a: MakeSummary, b: MakeSummary) {
    let stringA = a.name;
    let stringB = b.name;

    stringA = stringA?.replaceAll("Š", "S");
    stringB = stringB?.replaceAll("Š", "S");

    if (stringA == undefined || stringB == undefined)
        return 0;

    if (stringA < stringB ){
      return -1;
    }
    if (stringA > stringB ){
      return 1;
    }
    return 0;
}

export function sortMakeSummariesByName(makeSummaries: MakeSummary[]) {
    return (Object.assign([], makeSummaries) as MakeSummary[]).sort(compareSummariesByName);
  }