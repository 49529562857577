import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { INumberRegistrationInNetherlandsPerMake } from 'Communication/models/Tops';
import {
  Box,
  CircularProgress,
  Fade,
  MenuItem,
  Paper,
  Select,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ResponsiveLine, Serie } from '@nivo/line'
import moment from 'moment';
import { Info } from '@mui/icons-material';
import { VoertuigVinderApi } from 'Communication/VoertuigVinderAPI/Client';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingPlaceholderContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
      minHeight: `calc(430px - ${theme.spacing(4)})`
    },
    titleContainer: {
      display: 'inline-flex',
      alignItems: 'center',
      '& svg': {
        marginLeft: theme.spacing(1)
      }
    },
    startYearSelect: {
      position: 'absolute',
      left: theme.spacing(1),
      bottom: theme.spacing(1),
      zIndex: 1,
    },
    endYearSelect: {
      position: 'absolute',
      right: theme.spacing(1),
      bottom: theme.spacing(1),
      zIndex: 1,
    }
  })
);

export default function RegisteredPerMakePerYearGraph() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const [numberRegistrationInNetherlandsPerMake, setNumberRegistrationInNetherlandsPerMake] = React.useState<INumberRegistrationInNetherlandsPerMake[]>();
  const [startYear, setStartYear] = React.useState(Math.floor(moment().year() / 10) * 10 - (isSmall ? 20 : 40));
  const [endYear, setEndYear] = React.useState(moment().year());
  const [serieData, setSerieData ] =  React.useState<Serie[]>();
  const history = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (numberRegistrationInNetherlandsPerMake !== undefined) {
      setNumberRegistrationInNetherlandsPerMakeAsSerieData();
    }
  }, [startYear, endYear, numberRegistrationInNetherlandsPerMake]);

  async function initialize() {
    let RdwMakeRegistrations = await VoertuigVinderApi.getRdwRegistrationsFromTopMakes();
    let makeIds = Object.keys(RdwMakeRegistrations);
    let makes = await Promise.all(makeIds.map(mid => VoertuigVinderApi.getMakeById(mid)));
    let allRdwMakeRegistrations:INumberRegistrationInNetherlandsPerMake[] = [];
    makes.forEach(m => {
      RdwMakeRegistrations[m.id!].forEach(rdwData => {
        allRdwMakeRegistrations.push({ make: m.name ?? 'Onbekend', imported: rdwData.numberOfImportVehicleInNetherlands ?? 0, originalDutch: rdwData.numberOfNewVehiclesInNetherlands ?? 0, registrationYearNetherlands: rdwData.year?.toString() ?? '0000' } as INumberRegistrationInNetherlandsPerMake);
      });
    });
    setNumberRegistrationInNetherlandsPerMake(allRdwMakeRegistrations);
  }

  function setNumberRegistrationInNetherlandsPerMakeAsSerieData() {
    if (numberRegistrationInNetherlandsPerMake === undefined)
      return;

    let serieDataTmp: Serie[] = [];
    numberRegistrationInNetherlandsPerMake.forEach(rpmd => {
      if (+rpmd.registrationYearNetherlands < startYear || +rpmd.registrationYearNetherlands > endYear) {
        return;
      }

      let formattedName = rpmd.make.charAt(0) + rpmd.make.substr(1).toLowerCase();
      let existingData = serieDataTmp.find(sd => sd.id === formattedName);
      if (existingData === undefined) {
        serieDataTmp.push({ id: formattedName, data: [ { x: rpmd.registrationYearNetherlands, y: rpmd.originalDutch + rpmd.imported } ] });
      }
      else {
        existingData.data = [...existingData.data, { x: rpmd.registrationYearNetherlands, y: rpmd.originalDutch + rpmd.imported }];
      }
    });

    serieDataTmp.forEach(serie => {
      serie.data = [...serie.data].sort((n1,n2) => +(n1.x || 0) - +(n2.x || 0));
    });

    setSerieData(serieDataTmp);
  }

  function onLegendClick(d: any) {
    history(`/make/${d.id.toString().toUpperCase()}`);
  }

  function getStartYearSelect() {
    let options: JSX.Element[] = [];
    for (let i = 1960; i < endYear; i = i + 10) {
      options.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
    }

    return(<Select
      variant="standard"
      className={classes.startYearSelect}
      value={startYear}
      onChange={e => setStartYear(+(e.target.value as string))} >
        {options}
    </Select>)
  }

  function getEndYearSelect() {
    let options: JSX.Element[] = [];
    for (let i = startYear + 10; i < moment().year(); i = i + 10) {
      options.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
    }

    options.push(<MenuItem key={moment().year()} value={moment().year()}>{moment().year()}</MenuItem>);

    return(<Select
      variant="standard"
      className={classes.endYearSelect}
      value={endYear}
      onChange={e => setEndYear(+(e.target.value as string))} >
        {options}
    </Select>)
  }

  const GetContents = () => {
    if (serieData == null)
      return <div className={classes.loadingPlaceholderContainer}><Fade in timeout={5000}><CircularProgress size={120} /></Fade></div>
    
    return(
      <>
        <ResponsiveLine
          data={serieData}
          margin={{ top: 8, right: isSmall ? 16 : 128, bottom: isSmall ? 104 : 96, left: 64 }}
          pointLabel='y'
          pointSize={4}
          useMesh={true}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 65,
            legend: 'Jaar',
            legendPosition: 'middle',
            legendOffset: 48
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Aantal nieuw geregistreerd',
            legendPosition: 'middle',
            legendOffset: -50
          }}
          // tooltip={renderTooltip}
          legends={!isSmall ? [
            {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                onClick: onLegendClick,
                effects: [
                  {
                      on: 'hover',
                      style: {
                          itemBackground: 'rgba(0, 0, 0, .03)',
                          itemOpacity: 1
                      }
                  }
              ]
            }
          ] : []}
          tooltip={p => <Box p={1} component={Paper}>{p.point.id.split('.')[0]}: <b>{p.point.data.yFormatted}</b></Box>}
        />
        { getStartYearSelect() }
        { getEndYearSelect() }
      </>
    );
  }

  return (
    <Box height={430} position='relative' textAlign='center'>
      <div className={classes.titleContainer}><Typography variant="h5">Populairste merken</Typography>
        <Tooltip title={<div>{"Aantal personenauto's verkocht of geïmporteerd per jaar"}
          <br />
          {serieData == null ? "Er wordt door een groote hoeveelheid data gezocht, dit kan even duren. De volgende keer dat je deze pagina bezoekt zal dit sneller gaan." : ""}</div>}>
          <Info fontSize='small' />
        </Tooltip>
      </div>
      {GetContents()}
    </Box>
  );
}