import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

export default class AnalyticsHelper {
    private static analytics: firebase.analytics.Analytics | undefined = undefined;

    public static SetAnalytics(givenAnalytics: firebase.analytics.Analytics){
        AnalyticsHelper.analytics = givenAnalytics;
    }

    public static SetUserId(userId: string) {
        this.analytics?.setUserId(userId);
    }

    public static LogEvent(eventName: string, eventValue: object) {
        if (this.analytics !== undefined){
            this.analytics.logEvent(eventName, eventValue);
        }
    }

    public static LogScreenVisit(screenName: string){
        this.LogEvent(firebase.analytics.EventName.SCREEN_VIEW, { 'screen_name': screenName });
    }
}

