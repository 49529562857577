import React, { useEffect } from 'react';
import "firebase/compat/storage";
import "firebase/compat/auth";
import { useNavigate } from "react-router-dom";
import { Theme, Box, Typography, Grid, Card, CardContent, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import "firebase/compat/auth";
import FireAuthHelper from 'Helper/FireAuthHelper';
import { VoertuigVinderApi } from 'Communication/VoertuigVinderAPI/Client';
import Seo from 'Components/Seo/Seo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  })
);

export default function Admin() {
  const [numberOfTodoPhotos, setNumberOfTodoPhotos] = React.useState(0);
  const [numberOfTodoSocialMedia, setNumberOfTodoSocialMedia] = React.useState(0);
  const classes = useStyles();
  const history = useNavigate();

  useEffect(() => {
    initialize();
  });

  async function initialize() {
    await new Promise(resolve => setTimeout(resolve, 500));

    let user = FireAuthHelper.GetUser();

    if (!user?.isAdmin){
      return;
    }

    var todoPhotos = await VoertuigVinderApi.getVehicleSpotsForAdmin();
    setNumberOfTodoPhotos(todoPhotos.length);

    var todoSocialMedia = await VoertuigVinderApi.getSocialMediaForAdmin();
    setNumberOfTodoSocialMedia(todoSocialMedia.length);
  }

  return (
    <Box pb={6}>
      <Seo title='Admin' />
      <Typography variant="h1">Admin</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <Card>
            <CardContent>
              <form onSubmit={() => history('/admin/approve-photos')}>
                <Typography variant='h4' gutterBottom>
                  Photos
                </Typography>
                <Typography variant="body2" component="p">
                  {numberOfTodoPhotos == 0 ?
                  <div>No photos</div> :
                  <div>Number of photos to validate: {numberOfTodoPhotos}</div>}
                </Typography>
                <div>
                  <Button type='submit' variant="contained" color="primary" fullWidth>Beoordelen</Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Card>
            <CardContent>
              <form onSubmit={() => history('/admin/validate-owned-vehicle')}>
                <Typography variant='h4' gutterBottom>
                  Owned vehicles
                </Typography>
                <Typography variant="body2" component="p">
                  <div>No owned vehicles to validate</div>
                </Typography>
                <div>
                  <Button disabled type='submit' variant="contained" color="primary" fullWidth>Valideren</Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Card>
          <CardContent>
              <form onSubmit={() => history('/admin/approve-social-media')}>
                <Typography variant='h4' gutterBottom>
                  Social Media
                </Typography>
                <Typography variant="body2" component="p">
                  {numberOfTodoSocialMedia == 0 ?
                  <div>No Social Media to approve</div> :
                  <div>Number of socials to validate: {numberOfTodoSocialMedia}</div>}
                </Typography>
                <div>
                  <Button type='submit' variant="contained" color="primary" fullWidth>Beoordelen</Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Card>
          <CardContent>
              <form onSubmit={() => history('/admin/manage-makes')}>
                <Typography variant='h4' gutterBottom>
                  Manage makes
                </Typography>
                <div>
                  <Button type='submit' variant="contained" color="primary" fullWidth>Manage</Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}