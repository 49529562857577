import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/auth";
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Avatar, Box, Button, IconButton, Modal, Paper, Stack, Theme, Tooltip, Typography } from "@mui/material";
import { PersonAdd } from '@mui/icons-material';
import { v4 } from 'uuid';
import { VoertuigVinderApi } from "Communication/VoertuigVinderAPI/Client";
import { User, Vehicle } from "Communication/VoertuigVinderAPI/VoertuigVinderApiClient";
import FireStoreAuthHelper from "Helper/FireAuthHelper";
import { stringAvatar } from "Helper/UserHelper";

interface IProps {
    vehicle?: Vehicle;
    registration: string;
    countryCode: string;
    fireUser?: firebase.User;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ownerButtonContainer: {
        display: 'inline-block',

        '& a': {
            textDecoration: 'none',
        }
    },
    ownerButton: {
        margin: `-0.5em 0 0 ${theme.spacing(1)}`,
        padding: 0,
        '& svg': {
            fontSize: 40
        },
        '& img': {
            height: 40,
            borderRadius: 25,
        }
    },
    claimButton: {
        margin: `-0.5em 0 0 ${theme.spacing(1)}`,
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        borderWidth: 1,
  
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
          color: 'white',
        }
    },
    claimModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: theme.spacing(2),
        maxWidth: 600,
    },
    claimButtonBar: {
        paddingTop: theme.spacing(1),
    }
  }),
);

export default function Owner(props: IProps) {
    const classes = useStyles();
    const { vehicle, countryCode, registration, fireUser: user } = props;
    const [currentUser, setCurrentUser] = React.useState<User>();
    const [owner, setOwner] = React.useState<User>();
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);

    useEffect(() => {
        initialize();
      }, [countryCode, vehicle, registration, user]);

    async function initialize() {
        if (vehicle === undefined || vehicle?.registration !== registration )
            return;

        if (vehicle.ownerId != undefined && vehicle.ownerId !== '') {
            let ownerResult = await VoertuigVinderApi.getUserById(vehicle.ownerId);
            setOwner(ownerResult);
        }

        setCurrentUser(FireStoreAuthHelper.GetUser());
    }

    async function claimVehicle(){
        if (user === undefined) {
            return;
        }

        await VoertuigVinderApi.claimVehicleByRegistrationAndCountryCode(registration, countryCode, { ownedVehicleId: v4() });
        window.location.reload();
    }

    if (vehicle === undefined || (owner == undefined && vehicle?.ownerId != undefined && vehicle.ownerId !== ''))
      return <></>;

    if (vehicle?.ownerId != undefined && vehicle.ownerId !== ''){
        return (
            <div className={classes.ownerButtonContainer}>
                <Link to={`/user/profile/${vehicle.ownerId}`}>
                    <Tooltip title={`Geclaimed door ${owner?.userName} ${(!vehicle.ownerIsValidated && vehicle?.ownerId !== currentUser?.id) ? "(Is dit jouw voertuig? Neem dan contact op via voertuigvinder+garage@gmail.com)" : ''}`}>
                        <IconButton
                            className={classes.ownerButton}
                            color="inherit"
                            size="small">
                            {owner?.photoUrl !== undefined ? <img src={owner?.photoUrl ?? ''} /> : <Avatar {...stringAvatar(owner?.userName ?? '? ?')} />}
                        </IconButton>
                    </Tooltip>
                </Link>
            </div>
        );
    }

    if (user === undefined)
        return <></>;

    return (
        <>
            <IconButton size='small' className={classes.claimButton} onClick={() => setModalOpen(true)}>
                <PersonAdd />
            </IconButton>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Box component={Paper} className={classes.claimModal}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      Voertuig claimen
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      Als dit voertuig van jou is kan je deze claimen. Dit voertuig zal aan de garage op je profiel worden toegevoegd en andere gebruikers kunnen bij dit voertuig zien dat deze van jou is.
                    </Typography>
                    <Stack direction='row' alignItems='center' justifyContent='flex-end' spacing={2} className={classes.claimButtonBar}>
                        <Button variant="outlined" onClick={() => setModalOpen(false)}>Annuleren</Button>
                        <Button variant="contained" onClick={claimVehicle}>Claim</Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
}