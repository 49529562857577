import React, { useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  MenuItem,
  Select,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ResponsiveStream } from '@nivo/stream'
import moment from 'moment';
import FuelDataFillerTakeOver from './FuelDataFillerTakeOver';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import FireStoreHelper from 'Helper/FireStoreHelper';
import { uniq, flatMap } from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingPlaceholderContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
      minHeight: `calc(430px - ${theme.spacing(4)})`
    },
    titleContainer: {
      display: 'inline-flex',
      alignItems: 'center',
      '& svg': {
        marginLeft: theme.spacing(1)
      }
    },
    startYearSelect: {
      position: 'absolute',
      left: theme.spacing(1),
      bottom: theme.spacing(1),
      zIndex: 1,
    },
    endYearSelect: {
      position: 'absolute',
      right: theme.spacing(1),
      bottom: theme.spacing(1),
      zIndex: 1,
    }
  })
);

export default function FuelPerYearGraph() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const [startYear, setStartYear] = React.useState(Math.floor(moment().year() / 10) * 10 - (isSmall ? 20 : 40));
  const [endYear, setEndYear] = React.useState(moment().year());
  const [serieData, setSerieData ] =  React.useState<any[]>();
  const [fuelNames, setFuelNames ] =  React.useState<string[]>([]);
  const classes = useStyles();

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
  }, [startYear, endYear]);

  async function initialize() {
    let fleetStatisticsDocument = await FireStoreHelper.fireStore?.collection('fleet').doc('statistics')!.get().then((doc) => doc.data() );

    let fireData: any[] = [];

    let fuelPerYear = fleetStatisticsDocument?.fuelPerYear!;
    let years = Object.keys(fuelPerYear);
    let foundFuelNames = flatMap(years, year => Object.keys(fuelPerYear[year]));

    years.forEach(year => {
      let fuelDataForYear = { year: year };

      foundFuelNames.forEach(fuelType => {
        let foundFuelTypeCount = fuelPerYear[year][fuelType] ?? 0;
        let visibleFuelTypeName = correctFuelName(fuelType);

        if (fuelDataForYear[visibleFuelTypeName] === undefined){
          fuelDataForYear[visibleFuelTypeName] = foundFuelTypeCount;
        }else {
          fuelDataForYear[visibleFuelTypeName] = fuelDataForYear[visibleFuelTypeName] + foundFuelTypeCount;
        }
      });
      fireData.push(fuelDataForYear);
    });

    foundFuelNames = uniq(foundFuelNames.map(ffn => correctFuelName(ffn)));
    
    setFuelNames(uniq(foundFuelNames));
    setSerieData(fireData);
  }

  function correctFuelName(fuelName: string): string {
    switch (fuelName) {
      case 'Benzine+Elektriciteit+LPG':
      case 'Benzine+CNG+Elektriciteit':
      case 'Diesel+Elektriciteit':
      case 'Elektriciteit+LPG':
      case 'Alcohol+Elektriciteit':
      case 'Benzine+Elektriciteit': return 'Hybride';
      case 'Alcohol+Benzine+CNG':
      case 'Benzine+LNG':
      case 'Benzine+CNG+LPG':
      case 'Alcohol+Benzine':
      case 'Alcohol+Benzine+LPG':
      case 'Benzine+CNG':
      case 'Benzine+':
      case 'Benzine+LPG': return 'Benzine';
      case 'Elektriciteit+Waterstof': return 'Waterstof'
      case 'CNG':
      case 'LNG':
      case 'CNG+LPG':
      case 'LNG+LPG':
      case 'Alcohol':
      case 'Alcohol+CNG':
      case 'Alcohol+LPG': return 'Overige';
      default: return fuelName;
    }
  }

  function getStartYearSelect() {
    let options: JSX.Element[] = [];
    for (let i = 1980; i < endYear; i = i + 10) {
      options.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
    }

    return(<Select
      variant="standard"
      className={classes.startYearSelect}
      value={startYear}
      onChange={e => setStartYear(+(e.target.value as string))} >
        {options}
    </Select>)
  }

  function getEndYearSelect() {
    let options: JSX.Element[] = [];
    for (let i = startYear + 10; i < moment().year(); i = i + 10) {
      options.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
    }

    options.push(<MenuItem key={moment().year()} value={moment().year()}>{moment().year()}</MenuItem>);

    return(<Select
      variant="standard"
      className={classes.endYearSelect}
      value={endYear}
      onChange={e => setEndYear(+(e.target.value as string))} >
        {options}
    </Select>)
  }

  const GetContents = () => {
    if (serieData == null)
      return <div className={classes.loadingPlaceholderContainer}><Fade in timeout={5000}><CircularProgress size={120} /></Fade></div>
    
    var usedData = serieData.filter(sd => (startYear ? sd.year >= startYear : true) && (endYear ? sd.year <= endYear : true))

    return(
      <>
        <ResponsiveStream
        data={usedData}
        keys={fuelNames}
        margin={{ top: 8, right: isSmall ? 16 : 128, bottom: isSmall ? 112 : 104, left: 16 }}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 65,
            format: t => usedData[t as number]?.year,
        }}
        curve="cardinal"
        offsetType="expand"
        order="ascending"
        colors={{ scheme: 'set2' }}
        fillOpacity={0.8}
        borderColor={{ theme: 'background' }}
        dotColor={{ from: 'color' }}
        dotBorderWidth={2}
        dotBorderColor={{ from: 'color', modifiers: [ [ 'darker', 0.7 ] ] }}
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'column',
                translateX: 100,
                itemWidth: 80,
                itemHeight: 20,
                itemTextColor: '#999999',
                symbolSize: 12,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000000'
                        }
                    }
                ]
            }
        ]}
    />
        { getStartYearSelect() }
        { getEndYearSelect() }
      </>
    );
  }

  return (
    <Box height={430} position='relative' textAlign='center'>
      
      <div className={classes.titleContainer}>
        <FuelDataFillerTakeOver />
        <Typography variant="h5">Brandstof nieuwe voertuigen</Typography>
      </div>
      {GetContents()}
    </Box>
  );
}